import { MyTextInput } from 'Atoms/Formik';
import { Form, Formik } from 'formik';
import { ChangePassSchema } from 'Lib/validators';
import { Modal } from 'react-bootstrap';

import { useState } from 'react';
import { SubHeader } from '..';
import API from 'Services/api';
import toast, { Toaster } from 'react-hot-toast';

const Index = () => {
  const [show, setShow] = useState(false);
  const initialData = {
    currPass: '',
    pass: '',
    confirmPass: '',
  };

  return (
    <>
      <div className='container'>
        <Toaster />
        <SubHeader />
        <div className='row row-padding-top'>
          <div className='col-md-12'>
            <h1>Reset password</h1>
          </div>
        </div>

        <Formik
          initialValues={initialData}
          validationSchema={ChangePassSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              // console.log(values);
              const { data } = await API.post('/vendor/changePassword', {
                currentPassword: values.currPass,
                newPassword: values.pass,
              });
              if (data?.message === 'Success') {
                resetForm();
                setShow(false);
                toast.success('Your Password is updated');
              }
            } catch (error) {
              console.log(error);
              setShow(false);
              toast.error('Error');
            }
          }}
        >
          {(props) => {
            return (
              <Form>
                <div className='row '>
                  <div className='col-xl-6 col-md-6'>
                    <div className='row' style={{ paddingTop: '15px' }}>
                      <div className='col-xl-5 col-5'>
                        <label htmlFor='currPass'>Current password</label>
                      </div>
                      <div className='col-xl-7 col-7'>
                        <MyTextInput
                          type='password'
                          name='currPass'
                          className='form-control'
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-5 col-5'>
                        <label htmlFor='pass'>New password</label>
                      </div>
                      <div className='col-xl-7 col-7'>
                        <MyTextInput
                          type='password'
                          name='pass'
                          className='form-control'
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-5 col-5'>
                        <label htmlFor='confirmPass'>
                          Confirm new password
                        </label>
                      </div>
                      <div className='col-xl-7 col-7'>
                        <MyTextInput
                          type='password'
                          name='confirmPass'
                          className='form-control'
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row row-padding'>
                  <div className='col-md-6 col-sm-6 col-xs-12'>
                    <a
                      type='button'
                      className='btn btn-primary'
                      onClick={() => setShow(true)}
                    >
                      Reset
                    </a>

                    <Modal show={show} onHide={() => setShow(false)}>
                      <Modal.Header>
                        <h3>Confirm password change</h3>
                      </Modal.Header>
                      <Modal.Footer>
                        <button
                          className='btn btn-primary btn-outline-primary'
                          onClick={() => setShow(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          onClick={props.handleSubmit}
                        >
                          Change
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
export default Index;
