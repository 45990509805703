import { MyTextInput } from 'Atoms/Formik';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import API from 'Services/api';
import { SubHeader } from '..';

const Index = () => {
  const [searchDate, setSearchDate] = useState(new Date());
  const [counter, setCounter] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  
  const [parkId, setParkId] = useState(null);

  const router = useRouter();
  const todayDate = new Date();
  const initialData = {
    dateOfReservation: '',
    endDate: '',
    email: '',
    lastName: '',
    orderNo: '',
    type: '',
  };
  useEffect(() => {
    setParkId(JSON.parse(localStorage.getItem('vendor_login_data')).parkId);
    
    const getCounter = async (parkIdInitial) => {
      try {
        const { data } = await API.get(
          '/vendor/' + parkIdInitial + '/reservationCount?clientDate='+moment().format('YYYY-MM-DD')
        );
        // console.log(data?.data);
        setCounter(data?.data);
      } catch (err) {
        console.log(err);
      }
    };
    localStorage.removeItem('reservationSearch');
    
    const parkIdInitial = JSON.parse(localStorage.getItem('vendor_login_data')).parkId
    
    getCounter(parkIdInitial);
  }, []);

  return (
    <>
      <div className='container'>
        <SubHeader />

        <div className='row row-padding'>
          <div className='col-xl-2 col-md-2'>
            <h4>Overview:</h4>
          </div>
          <div className='col-xl-4 col-md-3'>
            <div className='light-blue padding'>
              <p>
                <strong>Today:</strong>{' '}
                {moment(todayDate).format('MMMM D, YYYY')}
                <br />
                Reservations: {counter && counter.reservationsCount.today}
              </p>
            </div>
          </div>
          <div className='col-xl-4 col-md-3'>
            <div className='light-blue padding'>
              <p>
                <strong>Tomorrow:</strong>{' '}
                {moment(todayDate).add(1, 'days').format('MMMM D, YYYY')}
                <br />
                Reservations: {counter && counter.reservationsCount.tomorrow}
              </p>
            </div>
          </div>
        </div>

        <div className='row line-bottom-dark'>
          <div className='col-xl-6 col-md-6'>
            <h2 style={{ margin: '0.5rem 0' }}>Search Reservations:</h2>
          </div>
          <div className='col-xl-6 col-md-6 text-end'>
            <a
              className='btn btn-primary btn-outline-primary'
              href='list-view'
              style={{ marginRight: '5px' }}
              onClick={async (values) => {
                try {
                  // console.log(values);
                  localStorage.setItem(
                    'reservationSearch',
                    JSON.stringify({
                      ...values,
                      dateOfReservation: startDate
                        ? moment(startDate).format('YYYY-MM-DD')
                        : '',
                      endDate: endDate
                        ? moment(endDate).format('YYYY-MM-DD')
                        : '',
                    })
                  );
                  // console.log(searchReservation);
                  router.push('/list-view');
                } catch (error) {
                  console.log(error);
                  // toast.error(error.response.data.error);
                }
              }}
            >
              List view
            </a>
            <Link href='/calendar-view'>
              <a className='btn btn-primary btn-outline-primary ml-1'>
                Calendar view
              </a>
            </Link>
          </div>
        </div>

        <Formik
          initialValues={initialData}
          onSubmit={async (values) => {
            try {
              localStorage.setItem(
                'reservationSearch',
                JSON.stringify({
                  ...values,
                  dateOfReservation: startDate
                    ? moment(startDate).format('YYYY-MM-DD')
                    : '',
                  endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
                })
              );
              router.push('/search-results');
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {(props) => {
            return (
              <Form>
                <div className='row row-padding-top'>
                  <div className='col-md-2 col-sm-2 col-xs-12'>
                    <label>Date</label>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <div className='input-group input-daterange'>
                      <DatePicker
                        id='datefield'
                        selectsRange={true}
                        name='dateOfReservation'
                        startDate={startDate}
                        endDate={endDate}
                        selected={searchDate}
                        onChange={(dates) => {
                          setStartDate(dates[0]);
                          setEndDate(dates[1]);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-2 col-sm-2 col-xs-12'>
                    <label htmlFor='people'>Email</label>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <MyTextInput
                      name='email'
                      type='email'
                      className='form-control'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-2 col-sm-2 col-xs-12'>
                    <label>Last name</label>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <MyTextInput
                      name='lastName'
                      type='text'
                      className='form-control'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-2 col-sm-2 col-xs-12'>
                    <label>Order #</label>
                  </div>
                  <div className='col-md-4 col-sm-4 col-xs-12'>
                    <MyTextInput
                      name='orderNo'
                      type='text'
                      className='form-control'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className='form-check form-check-inline'>
                      <Field
                        className='form-check-input'
                        type='radio'
                        name='type'
                        value='visitor'
                      />
                      <label
                        className='form-check-label'
                        htmlFor='inlineRadio1'
                      >
                        Visitor
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <Field
                        className='form-check-input'
                        type='radio'
                        name='type'
                        value='puc'
                      />
                      <label
                        className='form-check-label'
                        htmlFor='inlineRadio2'
                      >
                        PUC
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className='row row-padding-bottom'
                  // style="padding-top: 1.5rem;"
                  style={{ paddingTop: '1.5rem' }}
                >
                  <div className='col-md-6 col-sm-6 col-xs-12'>
                    <button
                      className='btn btn-primary btn-outline-primary'
                      type='reset'
                      onClick={() => {
                        setStartDate();
                        setEndDate();
                        setSearchDate(new Date());
                      }}
                    >
                      Clear
                    </button>
                    &nbsp;&nbsp;
                    <button type='submit' className='btn btn-primary'>
                      Search
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default Index;
