import React from 'react';
import { useField } from 'formik';

export default function MySelect({ ...props }) {
  const [field, meta] = useField(props);
  return (
    <div>
      <select {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className='error'>{meta.error}</div>
      ) : null}
    </div>
  );
}
