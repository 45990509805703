import React from 'react';

const Index = () => {
  return (
    <>
      <div className='container'>
        <div className='row row-padding'>
          <div className='col-xl-12'>
            <h2>For inquiries:</h2>
            <p>
              <strong>
                Department of Land and Natural Resources, Division of State
                Parks
              </strong>
              <br />
              <a href='https://dlnr.hawaii.gov/dsp/contact-us/' target='_blank'>
                https://dlnr.hawaii.gov/dsp/contact-us/
              </a>
            </p>
            <p>
              <strong>
                About your reservations at Diamond Head State Monument
              </strong>
              <br />
              <a href='http://propark.org/contact-us/' target='_blank'>
                http://propark.org/contact-us/
              </a>
            </p>
            <p>
              <strong>About PUC Registrations</strong>
              <br />
              The Hawaii Public Utilities Commission: (808) 586-2020
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
