import { Loader } from 'Atoms/';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import API from 'Services/api';
import { SubHeader } from '..';

const Index = () => {
  const [flag, setFlag] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [resultsPerPage, setResultsPerPage] = useState(25);
  const [pageNo, setPageNo] = useState(1);
  const [sortOrder, setSortOrder] = useState();
  const [noOfPage, setNoOfPage] = useState([]);
  const [fieldToSortBy, setFieldToSortBy] = useState();
  const [isTx, setIsTx] = useState(false);
  const router = useRouter();
  
  const [parkId, setParkId] = useState(null);

  useEffect(() => {
    setParkId(JSON.parse(localStorage.getItem('vendor_login_data')).parkId);
    
    if (!router.isReady) return;

    async function apiHit(searchReservation, parkIdInitial) {
      try {
        const { tx } = router.query;
        setIsTx(!!tx || false);
        const endpoint = tx ? '/vendor/transactions' : '/vendor/search';
        const { data } = await API.get(endpoint, {
          params: {
            ...searchReservation,
            pageNo,
            resultsPerPage,
            parkId: parkIdInitial
          },
        });
        const items = [];
        for (let i = 1; i <= data?.data?.totalNumOfPages; i++) {
          items.push(i);
        }

        if (data?.message === 'Success') {
          setReservations(data?.data?.results);
          setNoOfPage(items);
          setFlag(true);
        } else {
          console.log('Error');
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (localStorage.getItem('reservationSearch')) {
      const searchReservation = JSON.parse(
        localStorage.getItem('reservationSearch'),
      );
      
      const parkIdInitial = JSON.parse(localStorage.getItem('vendor_login_data')).parkId

      apiHit(searchReservation, parkIdInitial);
    }
  }, [pageNo, resultsPerPage, router.isReady]);

  async function sortByField(sortBy) {
    setFieldToSortBy(sortBy);
    const searchReservation = JSON.parse(
      localStorage.getItem('reservationSearch'),
    );
    setFlag(false);
    try {
      const endpoint = isTx ? '/vendor/transactions' : '/vendor/search';
      const { data } = await API.get(endpoint, {
        params: {
          ...searchReservation,
          pageNo,
          resultsPerPage,
          sortBy,
          sortOrder,
          parkId
        },
      });

      const items = [];
      for (let i = 1; i <= data?.data?.totalNumOfPages; i++) {
        items.push(i);
      }

      if (data?.message === 'Success') {
        setReservations(data?.data?.results);
        setNoOfPage(items);
        setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
      } else {
        throw new Error();
      }
    } catch (e) {
      toast.error('Failed to fetch reservations');
    } finally {
      setFlag(true);
    }
  }

  async function exportReservations() {
    try {
      const searchReservation = JSON.parse(
        localStorage.getItem('reservationSearch'),
      );
      const {
        dateOfReservation,
        fromDate,
        endDate,
        orderNo,
        email,
        lastName,
        type,
      } = searchReservation;
      const endpoint = isTx
        ? '/vendor/report/transactions'
        : '/vendor/report/reservations';

      const params = isTx
        ? { fromDate, endDate }
        : {
            dateOfReservation,
            endDate,
            orderNo: orderNo || '',
            email: email || '',
            lastName: lastName || '',
            type: type || '',
            parkId: parkId
          };
      const { data } = await API.get(endpoint, {
        params: {
          ...params,
          sortBy: fieldToSortBy || undefined,
          sortOrder: !sortOrder
            ? undefined
            : sortOrder === 'ASC'
            ? 'DESC'
            : 'ASC',
        },
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log('[err/report]', e.message);
      toast.error('Could not download report');
    }
  }

  if (!flag) {
    return <Loader />;
  }

  return (
    <>
      <div className='container'>
        <SubHeader />
        <Toaster />

        <div className='row row-padding'>
          <div className='col-xl-7 col-md-7'>
            <h2 style={{ margin: '.5rem 0' }}>Search results</h2>
          </div>
          <div className='col-xl-5 col-md-5 text-end'>
            <Link href='/dashboard'>
              <a
                className='btn btn-primary btn-outline-primary'
                style={{ marginRight: '0.4rem' }}
              >
                Search reservations
              </a>
            </Link>
            <Link href='/calendar-view'>
              <a className='btn btn-primary btn-outline-primary'>
                Calendar view
              </a>
            </Link>
          </div>
        </div>
        <div className='row line-bottom-dark g-3'>
          <div className='col'>
            <label htmlFor='people'>Results per page</label>
          </div>
          <div className='col'>
            <select
              className='form-control'
              id='state'
              placeholder='Choose one...'
              onChange={(e) => setResultsPerPage(e.target.value)}
              value={resultsPerPage}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={250}>250</option>
            </select>
          </div>
          <div className='col-xl-8 col-md-8 text-end'>
            <a
              className='btn btn-primary'
              href='#'
              onClick={exportReservations}
            >
              Export Reservations
            </a>
          </div>
        </div>

        <div className='row line-bottom Hide smaller-text'>
          <div className='col-xl-1 col-md-1 col-1 less-column-padding'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortByField('ORDER_DATE')}
            >
              <strong>Order date/time</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-1 col-1 less-column-padding'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortByField('ORDER_NUMBER')}
            >
              <strong>#</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortByField('NAME')}
            >
              <strong>Name</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-1 col-1 less-column-padding'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortByField('TYPE')}
            >
              <strong>Type</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-1 col-1 less-column-padding'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortByField('DATE_OF_RESERVATION')}
            >
              <strong>Reservation date</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-1 col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Ticket</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Qty</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Entry</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Price</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Time</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Status (Scanned Time)</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'></p>
          </div>
        </div>

        {reservations.map((reservation, index) => {
          return (
            <div className='row line-bottom smaller-text' key={index}>
              <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
                <p className='less-padding'>{reservation.orderDT}</p>
              </div>
              <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
                <p className='less-padding'>
                  <span className='showMobile'>#</span>
                  {reservation.orderNo}
                </p>
              </div>
              <div className='col-md-1 col-12 less-column-padding'>
                <p className='less-padding'>{reservation.userName}</p>
              </div>
              <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
                <p className='less-padding'>{reservation.type}</p>
              </div>
              <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
                <p className='less-padding'>{reservation.reservationDate}</p>
              </div>
              <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
                <p className='less-padding'>{reservation.ticketName}</p>
              </div>
              <div className='col-md-1 col-12 less-column-padding'>
                <p className='less-padding'>
                  <span className='showMobile'>Qty:</span> {reservation.qty}
                </p>
              </div>
              <div className='col-md-1 col-12 less-column-padding'>
                <p className='less-padding'>
                  <span className='showMobile'>Entry:</span> {reservation.entry}
                </p>
              </div>
              <div className='col-md-1 col-12 less-column-padding'>
                <p className='less-padding'>${reservation.price}</p>
              </div>
              <div className='col-md-1 col-12 less-column-padding'>
                {/* <p className='less-padding'>Morning 10:00am – 12:00pm</p> */}
                {reservation.timeSlots.map((time, idx) => {
                  return (
                    <p className='less-padding' key={idx}>
                      {time.label}
                    </p>
                  );
                })}
              </div>
              <div className='col-md-1 col-12 less-column-padding'>
                <p className='less-padding'>
                  {reservation.status?.toUpperCase()}
                  {reservation.codeScanCount === 1 &&
                    <span>({reservation.codeLastScannedAt})</span>
                  }  
                </p>
              </div>
              <div className='col-md-1 col-12 less-column-padding'>
                <p className='less-padding'>
                  <Link
                    href={`reservation-detail/${reservation.reservationId}`}
                  >
                    <a>View</a>
                  </Link>
                </p>
              </div>
            </div>
          );
        })}

        {/* <div className='row line-bottom smaller-text'>
          <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>8/9/2021 11:02am</p>
          </div>
          <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>
              <span className='showMobile'>#</span>54321
            </p>
          </div>
          <div className='col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>John Williams</p>
          </div>
          <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>Visitor</p>
          </div>
          <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>12/30/2021</p>
          </div>
          <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>Parking + Entry</p>
          </div>
          <div className='col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>
              <span className='showMobile'>Qty:</span> 2
            </p>
          </div>
          <div className='col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>
              <span className='showMobile'>Entry:</span> 3
            </p>
          </div>
          <div className='col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>$35.00</p>
          </div>
          <div className='col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>Morning 10:00am – 12:00pm</p>
          </div>
          <div className='col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>Active</p>
          </div>
          <div className='col-md-1 col-12 less-column-padding'>
            <p className='less-padding'>
              <a href='reservation-detail-2.html'>View</a>
            </p>
          </div>
        </div> */}

        <div className='row row-padding'>
          <div className='col-md-6 col-sm-6 col-xs-12'>
            <nav aria-label='Page navigation example'>
              <ul className='pagination'>
                <li className='page-item'>
                  <button
                    className='page-link '
                    aria-label='Previous'
                    disabled={pageNo === 1}
                    onClick={() => setPageNo((prev) => prev - 1)}
                  >
                    <span aria-hidden='true'>&laquo;</span>
                  </button>
                </li>
                {/* <li className='page-item active'>
                  <a className='page-link' href='#'>
                    1
                  </a>
                </li>
                <li className='page-item'>
                  <a className='page-link' href='#'>
                    2
                  </a>
                </li>
                <li className='page-item'>
                  <a className='page-link' href='#'>
                    3
                  </a>
                </li> */}
                {noOfPage.map((item, idx) => {
                  return (
                    <li
                      className={`page-item ${pageNo === item && 'active'}`}
                      key={idx}
                    >
                      <button
                        className='page-link'
                        onClick={() => setPageNo(item)}
                      >
                        {item}
                      </button>
                    </li>
                  );
                })}
                <li className='page-item'>
                  <button
                    className='page-link'
                    aria-label='Next'
                    onClick={() => setPageNo((prev) => prev + 1)}
                    disabled={pageNo === noOfPage.length}
                  >
                    <span aria-hidden='true'>&raquo;</span>
                  </button>
                </li>
              </ul>
            </nav>

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
