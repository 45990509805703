import * as Yup from 'yup';

const CRValidationSchema = Yup.object({
  firstName: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  verifyemail: Yup.string()
    .required('Please confirm your email address')
    .when('email', {
      is: (email) => (email && email.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('email')], "Email doesn't match"),
    }),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  zipcode: Yup.string().required('Required'),
  telephone: Yup.string().required('Required'),
});
export default CRValidationSchema;
