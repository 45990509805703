import Loader from '../../Atoms/Loader';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import API from '../../../services/api';
import 'react-datepicker/dist/react-datepicker.css';
import { STATIC_PARK_ID } from 'src/constants/misc';
import toast, { Toaster } from 'react-hot-toast';
import { SubHeader } from '..';
import moment from 'moment';
import Link from 'next/link';
import { useRouter } from 'next/router';

const ViewScheduledCapacity = ({ dataItem }) => {
  const router = useRouter();
  const { changeId } = router.query;
  console.log('changeId:'+changeId);
  const datepickerRef = useRef(null);

  const [effectiveDate, setEffectiveDate] = useState(null);
  const [updateType, setUpdateType] = useState(null);
  const [enteries,setEntries]=useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await API.get(`/parks/${STATIC_PARK_ID}/viewCapacityChanges?changeId=${changeId}`);
        setEffectiveDate(data.data.effectiveDate);
        setUpdateType(data.data.type);
        setEntries(data.data.entries);
        console.log('scheduledCapacities:'+JSON.stringify(data.data));
      } catch (e) {
        //if (e.response.data.error) toast.error(e.response.data.message);
        //else toast.error('Something went wrong!');
        setEntries([]);
      }
    };

    getData();

    
  }, []);

  const handleDelete = async () => {
    try {
      //alert('hello');
      
       const { data } = await API.delete(`/parks/61b977bae43345bd027d7bec/delete/scheduledCapacity?changeId=${changeId}`);
        $('#exampleModal').modal('toggle');
      // const { data } = await API.get(`/reservations/${_id}/details`);
      // console.log(data);
      // if (data.message === 'Success') {
      //   localStorage.removeItem('order_data');
      //   localStorage.removeItem('booking_data');
      //   localStorage.removeItem('customer_input');
      //   localStorage.removeItem('bookingDetails');
      //   localStorage.setItem('order_data', JSON.stringify(data?.data));
      //   console.log(data?.data);
      //   localStorage.setItem('should_show', JSON.stringify(false));
           router.push('/settings');
      // }
    } catch (error) {
      alert(error);
    }
  };
  const handleDeleteCancel = async () => {
    try {
      $('#exampleModal').modal('toggle');
      // const { data } = await API.get(`/reservations/${_id}/details`);
      // console.log(data);
      // if (data.message === 'Success') {
      //   localStorage.removeItem('order_data');
      //   localStorage.removeItem('booking_data');
      //   localStorage.removeItem('customer_input');
      //   localStorage.removeItem('bookingDetails');
      //   localStorage.setItem('order_data', JSON.stringify(data?.data));
      //   console.log(data?.data);
      //   localStorage.setItem('should_show', JSON.stringify(false));
      //   router.push('/diamondhead/reserve-confirmation');
      // }
    } catch (error) {
      alert(error);
    }
  };

  
  return (
    <div className='container'>
      <div>
        <Toaster />
      </div>
      <SubHeader />
      <div className="row row-padding-top line-bottom-dark">
        <div className="col-12">
                <h2>View capacity changes</h2>

        </div>
      </div>
      <div className="row row-padding-top line-bottom-dark">
        <div className="col-12">
                <p>Effective Date {effectiveDate} : {updateType}</p>

        </div>
      </div>
      <div className="row line-bottom">
				    <div className="col-xl-4 col-md-3 col-4">
				      <p className="less-padding"><strong>Ticket</strong></p>
				    </div>
				    <div className="col-xl-4 col-md-3 col-4">
				      <p className="less-padding"><strong>Timeslot</strong></p>
				    </div>
				    <div className="col-xl-4 col-md-3 col-4">
				      <p className="less-padding"><strong>New capacity</strong></p>
				    </div>
		   </div>
      {enteries === null ? (
        <Loader />
      ) : (
        <>
          {Object.keys(enteries).map((i, ind) => (
            <div className="row line-bottom">
                <div className="col-xl-4 col-md-3 col-4">
                  <p className="less-padding">{enteries[i].ticket}</p>
                </div>
                <div className="col-xl-4 col-md-3 col-4">
                  <p className="less-padding">{enteries[i].timeslot}</p>
                </div>
                <div className="col-xl-4 col-md-3 col-4">
                  <p className="less-padding">{enteries[i].newCapacity}</p>
                </div>
          </div>
          ))}
        </>
      )}
      
      <div className="row row-padding-bottom">
   <div className="col-md-12 text-end" style={{ paddingTop: '15px' }}>
     <a className="btn btn-primary btn-outline-primary" href="/settings" style={{ marginRight: '0.4rem' }}>
   		 Go back
   		</a>
   		<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
   Delete
  </button>
    <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header text-start">
            <p>Would you like to delete the settings?</p>
      </div>
          <div className="modal-footer">
             <button type="button" className="btn btn-primary btn-outline-primary" onClick={() => handleDeleteCancel()}>Cancel</button>
             <a className="btn btn-primary" onClick={() => handleDelete()}>Delete</a>
          </div>
        </div>
      </div>
    </div>
   		

   </div>



   </div>

    </div>
  );
};

export default ViewScheduledCapacity;
