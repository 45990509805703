import Cookies from 'js-cookie';
import { UserContext } from 'Lib/contexts/GlobalContext';
import Link from 'next/link';
import router from 'next/router';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';

const Index = ({ isInquiry = true }) => {
  const { isLogin, setIsLogin } = useContext(UserContext);

  useEffect(() => {
    if (Cookies.get('token')) {
      setIsLogin(true);
    }
  }, []);

  // Logout Functionality
  const handleClick = () => {
    console.log('Clicked Logout');

    localStorage.clear();
    Cookies.remove('token');
    Cookies.remove('userId');

    router.push('/');
    setIsLogin(false);
    toast.success('Logged Out');
  };

  return (
    <>
      <div className={isInquiry && isLogin ? 'header-int ' + JSON.parse(localStorage.getItem('parkData')).parkUrl + 'Banner' : 'header-int'}>
        <header className='header-repeater'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-7 col-lg-7 col-12'>
                <Link href='/'>
                  <a className='navbar-brand'>
                    <img
                      src='../images/go-hawaii-state-parks.png'
                      alt="Go Hawai'i state Parks logo"
                      width='414'
                      className='img-fluid'
                    />
                  </a>
                </Link>
              </div>
              <div className='col-xl-5 col-lg-5 col-12 link-top text-end'>
                {isLogin ? (
                  <Link href='/'>
                    <a onClick={handleClick}>LOGOUT</a>
                  </Link>
                ) : (
                  <Link href='/'>
                    <a>LOGIN</a>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </header>

        {isInquiry && isLogin && (
          <div className='container'>
            <h1 className='display-1 small-header'>
              <span>{JSON.parse(localStorage.getItem('parkData')).parkName}</span>
              <br />
              Management Dashboard
            </h1>
          </div>
        )}
      </div>
      {/* <div className='blue'></div> */}
    </>
  );
};

export default Index;
