import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import API from 'Services/api';

const Index = ({ orderDetails }) => {
  const router = useRouter();
  const [isChecked, setIsChecked] = useState(false);
  console.log('orderDetails', orderDetails);
  const { reservationData, customerData, pricingDetails } = orderDetails;
  const { _id, orderNo } = reservationData;
  const { pricingDetails: bookingDetails, total } = pricingDetails;
  const {
    firstName,
    lastName,
    email,
    country,
    address,
    city,
    stateProvince,
    mobile,
    zip,
  } = customerData;
  const waive=JSON.parse(localStorage.getItem("waive"));
  const waiveFee=JSON.parse(localStorage.getItem("waiveFee"));
  // console.log(waive);

  const handleCancel = async () => {
    try {
      const { data } = await API.post(`vendor/reservations/cancel/visitor`, {
        reservationId: _id,
        waive:waive,
        waiveFee:waiveFee,
        origin:'vendor'
      });
      if (data.message === 'Success') {
        toast.success('Your reservation has been cancelled');
        console.log(data?.data);
        document.getElementById('cancelmodel-close').click();
        localStorage.setItem('cancel_order_no', JSON.stringify(orderNo));
        router.push('/cancel-confirm');
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className='container'>
      <div>
        <Toaster />
      </div>
      <div className='row row-padding-top'>
        <div className='col-md-12'>
          <h1>Cancel reservation</h1>
        </div>
      </div>
      <div className='row line-bottom'>
        <div className='col-xl-2 col-md-2 col-2'>
          <p className='less-padding'>
            <strong>Ticket</strong>
          </p>
        </div>
        <div className='col-xl-1 col-md-2 col-2'>
          <p className='less-padding'>
            <strong>Qty</strong>
          </p>
        </div>
        <div className='col-xl-2 col-md-2 col-3'>
          <p className='less-padding'>
            <strong>Date</strong>
          </p>
        </div>
        <div className='col-xl-3 col-md-4 col-3'>
          <p className='less-padding'>
            <strong>Time</strong>
          </p>
        </div>
        <div className='col-2 text-end'>
          <p className='less-padding'>
            <strong>Price</strong>
          </p>
        </div>
      </div>
      {bookingDetails.map(({ ticket, qty, date, time, price }, index) => (
        <div className='row line-bottom'>
          <div className='col-xl-2 col-md-2 col-2'>
            <p className='less-padding'>{ticket}</p>
          </div>
          <div className='col-xl-1 col-md-2 col-2'>
            <p className='less-padding'>{qty}</p>
          </div>
          <div className='col-xl-2 col-md-2 col-3'>
            <p className='less-padding'>{date}</p>
          </div>
          <div className='col-xl-3 col-md-4 col-3'>
            <p className='less-padding'>{time}</p>
          </div>
          <div className='col-2 text-end'>
            <p className='less-padding'>${price}</p>
          </div>
        </div>
      ))}

      <div className='row line-bottom'>
        <div className='col-xl-2 col-md-2 col-2'></div>
        <div className='col-md-1 col-1'></div>
        <div className='col-xl-2 col-md-3 col-3'></div>
        <div className='col-xl-3 col-md-4 col-4'>
          <p className='less-padding text-end'>Total</p>
        </div>
        <div className='col-2 text-end'>
          <p className='less-padding'>${total}</p>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-2 col-4'>
          <p className='less-padding'>Order #:</p>
        </div>
        <div className='col-xl-5 col-8'>
          <p className='less-padding'>{orderNo}</p>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-2 col-4'>
          <p className='less-padding'>Your name:</p>
        </div>
        <div className='col-xl-5 col-8'>
          <p className='less-padding'>
            {firstName} {lastName}
          </p>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-2 col-4'>
          <p className='less-padding'>Email:</p>
        </div>
        <div className='col-xl-5 col-8'>
          <p className='less-padding'>{email}</p>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-2 col-4'>
          <p className='less-padding'>Address:</p>
        </div>
        <div className='col-xl-5 col-8'>
          <p className='less-padding'>
            {address}
            <br />
            {city}, {stateProvince} {zip} {country}
          </p>
        </div>
      </div>

      <div className='row line-bottom'>
        <div className='col-xl-2 col-4'>
          <p className='less-padding'>Telephone:</p>
        </div>
        <div className='col-xl-5 col-8'>
          <p className='less-padding'>{mobile}</p>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-12 col-12'>
          <p>
            <span className='red'>Cancellation Policy:</span> Once you cancel
            your reservation, you will no longer be able to use your ticket with
            the QR Code you received. You may not be able to make the
            reservation for the same day/time after you cancel your
            reservations. Please read the cancellation policy here&gt;&gt;
            <br />
            <Link href='/diamondhead/about'>
              <a target='_blank'>
                https://dev.gohawaiistateparks.com/diamondhead/about
              </a>
            </Link>
            <br />
          </p>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              id='flexCheckDefault'
            />
            <label className='form-check-label' for='flexCheckDefault'>
              Please check here that you understand and agree to the
              cancellation policy.
            </label>
          </div>
        </div>
      </div>

      <div className='row row-padding-top'>
        <div className='col-md-12'>
          <Link href={`/reservation-detail/${_id}`}>
            <a className='btn btn-primary btn-outline-primary'>Back</a>
          </Link>
          <button
            type='button'
            className='btn btn-primary ml-2'
            data-toggle='modal'
            data-target='#exampleModal'
            disabled={!isChecked}
            style={{ background: '#447969' }}
          >
            Cancel reservation
          </button>

          <div
            className='modal fade'
            id='exampleModal'
            tabindex='-1'
            role='dialog'
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
          >
            <div
              className='modal-dialog modal-dialog-centered modal-dialog-scrollable'
              role='document'
            >
              <div className='modal-content'>
                <div className='modal-header text-start'>
                  <h3>Are you sure you want to cancel this reservation?</h3>
                </div>
                <div className='modal-footer'>
                  <button onClick={handleCancel} className='btn btn-primary'>
                    Yes
                  </button>{' '}
                  <button
                    id='cancelmodel-close'
                    data-dismiss='modal'
                    className='btn btn-primary btn-outline-primary'
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />
    </div>
  );
};

export default Index;
