// import { Quantity } from 'Lib/constants';
import moment from 'moment';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast, { Toaster } from 'react-hot-toast';
import API from 'Services/api';
import { SubHeader } from '..';
import { TimerContext } from '../../../lib/contexts/TimerContext';
import { Modal } from 'react-bootstrap';

const Index = ({ orderDetails }) => {
  let router = useRouter();
  const { clearTimer, getDeadTime, stopTimer } = useContext(TimerContext);

  const [formData, setFormData] = useState('');
  const datepickerRef = useRef(null);
  const { pricingDetails, reservationData, customerData, ticketData } =
    orderDetails;
  const { pricingDetails: reservationDetails, total } = pricingDetails;
  const {
    _id: reservationId,
    parkId,
    ticketId,
    quantity,
    timeslots,
    dateOfReservation,
    segment,
    orderNo,
    maxLimit,
  } = reservationData;
  const [bookingDate, setBookingDate] = useState(new Date(dateOfReservation));
  const [reservationQuantity, setReservationQuantity] = useState(quantity);
  const [timeSlotsData, setTimeSlotsData] = useState([]);
  const [dataChanged, setDataChanged] = useState(true);
  const [show, setShow] = useState(false);
  let timeslotsFiltered = timeslots.map(({ id, label }) => ({
    id: Number(id),
    label,
  }));
  const [timeSlots, setTimeSlots] = useState(timeslotsFiltered);
  
  const [dropOffTicketId, setDropOffTicketId] = useState('');

  // const ticket = ticketData.filter((t) => t._id === ticketId)[0];
  // const maxLimit = ticket.maxLimit || Number.POSITIVE_INFINITY;
  customerData.orderNo = orderNo;

  useEffect(() => {
    async function apiHit() {
      const { data } = await API.get(
        '/parks/' + parkId + '/timeslots',
        {
          params: {
            segment,
            ticketId,
            quantity: reservationQuantity,
            date: moment(bookingDate).format('YYYY-MM-DD'),
          },
        }
      );
      if (data?.message === 'Success') {
        const dropOffTypeFilter = data?.data?.ticketTypes.filter(ticketType => ticketType.option === 'Drop-off');
        
        if(dropOffTypeFilter.length) {
          setDropOffTicketId(dropOffTypeFilter[0].value);
        }
        
        setFormData(data?.data);
        setTimeSlotsData(data?.data?.timeslots);
      }
    }
    apiHit();
  }, [reservationQuantity, bookingDate]);

  const { ticketTypes } = formData;
  let parkandentryTypes = [];
  ticketTypes?.forEach((item) => {
    // NOTE: This is a WIP change and has not been tested.
    // Please revert to the original if needed.
    // Instead of checking for "Parking and entry", we check for "parking" to
    // allow all "parking" type slots.
    if (item.option.toLowerCase().includes('parking')) {
      parkandentryTypes.push(item.value);
    }
  });

  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current;
    datepickerElement.setFocus(true);
  }

  function isSlotPresent(slotId) {
    for (let i = 0; i < timeSlots.length; i++) {
      if (timeSlots[i].id === slotId) {
        return true;
      }
    }
    return false;
  }
  const handleGoBack = () => {
    router.push(`/reservation-detail/${reservationId}`);
    document.getElementById('close-modal-button').click();
  };

  const handleCheckReservationUpdate = async () => {
    if (ticketId !== dropOffTicketId) {
      if (timeSlots.length === 0) {
        toast.error('Please select a time slot');
        return;
      }
    }
    try {
      const timeslots = timeSlots.map((item) => {
        const obj = {
          id: `${item.id}`,
          label: item.label,
        };
        return obj;
      });

      const updatedData = {
        reservationId,
        parkId,
        date: moment(new Date(bookingDate)).format('YYYY-MM-DD'),
        ticketId,
        quantity: reservationQuantity,
        timeslots
      };
      const { data } = await API.post(
        '/reservations/checkReservationUpdate',
        updatedData
      );
      
      updatedData.type = (orderDetails.reservationData.ticketType === '3' ? 'puc' : 'visitor');
      
      if (data.message === 'Success') {
        localStorage.setItem(
          'updated_reservation_data',
          JSON.stringify(data?.response)
        );
        localStorage.removeItem('updated_data');
        localStorage.setItem('updated_data', JSON.stringify(updatedData));
        localStorage.setItem('customer_data', JSON.stringify(customerData));
        localStorage.setItem('pricing_data', JSON.stringify(pricingDetails));
        localStorage.setItem(
          'reservation_data',
          JSON.stringify(reservationData)
        );
        setDataChanged(data?.response?.dataChanged);
        handleShow();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // console.log('MaxLimit:', maxLimit);
  const Quantity = maxLimit
    ? [...Array(maxLimit).keys()].map((i) => i + 1)
    : null;

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <div className='container'>
      <div>
        <Toaster />
      </div>
      <SubHeader />
      <div className='row row-padding-top'>
        <div className='col-md-12'>
          <h1>Change reservation</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <h3>Your current reservation:</h3>
        </div>
      </div>
      <div className='row line-bottom'>
        <div className='col-xl-3 col-md-3 col-3'>
          <p className='less-padding'>
            <strong>Ticket</strong>
          </p>
        </div>
        <div className='col-xl-1 col-md-2 col-2'>
          <p className='less-padding'>
            <strong>Qty</strong>
          </p>
        </div>
        <div className='col-xl-2 col-md-2 col-3'>
          <p className='less-padding'>
            <strong>Date</strong>
          </p>
        </div>
        <div className='col-xl-2 col-md-3 col-2'>
          <p className='less-padding'>
            <strong>Time</strong>
          </p>
        </div>
        <div className='col-2 text-end'>
          <p className='less-padding'>
            <strong>Price</strong>
          </p>
        </div>
      </div>
      {reservationDetails.map(({ ticket, qty, date, time, price }, index) => (
        <div key={index} className='row line-bottom'>
          <div className='col-xl-3 col-md-3 col-3'>
            <p className='less-padding'>{ticket}</p>
          </div>
          <div className='col-xl-1 col-md-2 col-2'>
            <p className='less-padding'>{qty}</p>
          </div>
          <div className='col-xl-2 col-md-2 col-3'>
            <p className='less-padding'>{date}</p>
          </div>
          <div className='col-xl-2 col-md-3 col-2'>
            <p className='less-padding'>{time}</p>
          </div>
          <div className='col-2 text-end'>
            <p className='less-padding'>${price}</p>
          </div>
        </div>
      ))}
      <div className='row line-bottom-dark'>
        <div className='col-xl-2 col-md-2 col-2'></div>
        <div className='col-md-1 col-1'></div>
        <div className='col-xl-2 col-md-3 col-3'></div>
        <div className='col-xl-3 col-md-4 col-4'>
          <p className='less-padding text-end'>Total</p>
        </div>
        <div className='col-2 text-end'>
          <p className='less-padding'>${total}</p>
        </div>
      </div>
      <form method='post'>
        <div style={{ border: '1px solid #bdd4db' }}>
          <div className='row reservation'>
            <div className='col-12'>
              <h3>Change your reservation to:</h3>
              <div className='row row-right-left-padding'>
                <div className='col-md-4 col-sm-4 col-xs-12'>
                  <select
                    className='form-control'
                    id='exampleFormControlSelect2'
                    aria-label='Ticket type'
                    value={ticketId}
                    disabled
                    // onChange={(e) => setTicketType(e.target.value)}
                  >
                    {ticketTypes?.map(({ value, option }, index) => (
                      <option key={index} value={value}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-md-3 col-sm-3 col-xs-12'>
                  <div className='input-group input-daterange'>
                    <DatePicker
                      id='datefield'
                      minDate={new Date(Date.now())}
                      selected={bookingDate}
                      onChange={(date) => setBookingDate(date)}
                      placeholderText='Booking Date'
                      ref={datepickerRef}
                    />
                  </div>
                </div>
                {ticketId !== dropOffTicketId && (
                  <div className='col-md-2 col-sm-2 col-xs-12'>
                    {maxLimit ? (
                      <select
                        className='form-control'
                        id='Quantity'
                        aria-label='Quantity'
                        onChange={(e) => setReservationQuantity(e.target.value)}
                        style={{ margin: '0 auto', width: '80px' }}
                      >
                        {Quantity.map((item) => (
                          <option
                            key={item}
                            selected={item === reservationQuantity}
                          >
                            {item}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type='number'
                        className='form-control'
                        id='Quantity'
                        aria-label='Quantity'
                        value={reservationQuantity}
                        onChange={(e) => setReservationQuantity(e.target.value)}
                        placeholder='Entry'
                      />
                    )}
                  </div>
                )}
              </div>

              {ticketId !== dropOffTicketId && (
                <div className='row row-right-left-padding'>
                  <div className='col-md-12 col-sm-12 col-xs-12'>
                    <div className='input-group mb-3'>
                      {timeSlotsData?.map((item) => (
                        <a
                          key={item.slotId}
                          className={`btn btn-primary time ${
                            item.capacity === 0 && 'booked'
                          }`}
                          style={{
                            backgroundColor:
                              isSlotPresent(item.slotId) && '#396a5b',
                            color: isSlotPresent(item.slotId) && '#fff',
                            pointerEvents: item.capacity === 0 && 'none',
                          }}
                          onClick={() => {
                            if (isSlotPresent(item.slotId)) {
                              setTimeSlots(
                                timeSlots.filter(
                                  (slot) => slot.id !== item.slotId
                                )
                              );
                            } else {
                              if (timeSlots.length === 0) {
                                setTimeSlots([
                                  { id: item.slotId, label: item.label },
                                ]);
                              } else if (parkandentryTypes.includes(ticketId)) {
                                if (timeSlots.length === 1) {
                                  if (
                                    Number(item.slotId) ===
                                      Number(timeSlots[0].id) + 1 ||
                                    Number(item.slotId) ===
                                      Number(timeSlots[0].id) - 1
                                  ) {
                                    setTimeSlots((prev) => [
                                      ...prev,
                                      { id: item.slotId, label: item.label },
                                    ]);
                                  } else {
                                    toast.error('Selected wrong time slot.');
                                  }
                                } else {
                                  toast.error('Select only two time slots.');
                                }
                              } else {
                                toast.error('Select only one time slot.');
                              }
                            }
                          }}
                        >
                          {item.label}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className='row row-padding-top'>
        <div className='col-md-12'>
          <Link href={`/reservation-detail/${reservationId}`}>
            <a className='btn btn-primary btn-outline-primary'>Cancel</a>
          </Link>
          <a
            // href='#'
            className='btn btn-primary ml-2'
            onClick={handleCheckReservationUpdate}
          >
            Save all changes
          </a>

          {/* <div
            className='modal fade'
            id='exampleModalName'
            tabIndex='-1'
            role='dialog'
            aria-labelledby='exampleModalNameLabel'
            aria-hidden='true'
          >
            <div
              className='modal-dialog modal-dialog-centered modal-dialog-scrollable'
              role='document'
            >
              <div className='modal-content'>
                <div className='modal-header text-start'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <p>Are you sure you want to update the reservation?</p>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    className='btn btn-primary btn-outline-primary'
                    data-dismiss='modal'
                    aria-label='Close'
                    id='close-modal-button'
                  >
                    Cancel
                  </button>
                  {dataChanged ? (
                    <button
                      onClick={() => {
                        stopTimer();
                        clearTimer(getDeadTime());
                        router.push('/change-reservation-confirm');
                        document.getElementById('close-modal-button').click();
                      }}
                      className='btn btn-primary'
                    >
                      Confirm
                    </button>
                  ) : (
                    <button onClick={handleGoBack} className='btn btn-primary'>
                      Go Back
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div> */}

          <Modal show={show} onHide={handleClose}>
            <Modal.Body>
              <div className='modal-header text-start'>
                <div className='row'>
                  <div className='col-md-12'>
                    <p>Are you sure you want to update the reservation?</p>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className='btn btn-primary btn-outline-primary'
                onClick={handleClose}
              >
                Cancel
              </button>
              {dataChanged ? (
                <button
                  type='submit'
                  className='btn btn-primary'
                  onClick={() => {
                    stopTimer();
                    clearTimer(getDeadTime());
                    router.push('/change-reservation-confirm');
                    handleClose();
                  }}
                >
                  Confirm
                </button>
              ) : (
                <button onClick={handleGoBack} className='btn btn-primary'>
                  Go Back
                </button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Index;
