import React from 'react';
import API from 'Services/api';
import { Formik, Form } from 'formik';
import { MyTextInput } from 'Atoms/Formik';
import toast, { Toaster } from 'react-hot-toast';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { PUCMakePasswordSchema } from 'Lib/validators';

export default function MakePassword() {
  const router = useRouter();

  var initialValues = {
    password: '',
    confirmPassword: ''
  };

  const { query } = useRouter();

  return (
    <div>
      <div>
        <Toaster />
      </div>
      <div className='container'>
        {query.token ? (
          <Formik
            initialValues={initialValues}
            validationSchema={PUCMakePasswordSchema}
            onSubmit={async values => {
              try {
                const { data } = await API.post(
                  '/vendor/resetPassword',
                  {
                    newPassword: values.password,
                    token: query.token
                  }
                );
                if (data?.message === 'Success') {
                  toast.success('Password Updated Successfully');
                  router.push('/');
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            {props => {
              return (
                <Form>
                  <div className='row row-padding-top'>
                    <div className='col-xl-8 col-md-8'>
                      <h2>Please set your password below:</h2>
                      <div className='row' style={{ paddingTop: '15px' }}>
                        <div className='col-xl-4 col-4'>
                          <label htmlFor='people'>Password</label>
                        </div>
                        <div className='col-xl-8 col-8'>
                          <MyTextInput
                            name='password'
                            type='password'
                            className='form-control'
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-xl-4 col-4'>
                          <label htmlFor='people'>Confirm password</label>
                        </div>
                        <div className='col-xl-8 col-8'>
                          <MyTextInput
                            name='confirmPassword'
                            type='password'
                            className='form-control'
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row row-padding'>
                    <div className='col-md-6 col-sm-6 col-xs-12'>
                      <button type='submit' className='btn btn-primary'>
                        Set Password
                      </button>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div
            className='container'
            style={{ margin: '25vh 0', textAlign: 'center' }}
          >
            Invalid link, please request a new link{' '}
            <Link href='/'>
              <a>here</a>
            </Link>
            .
          </div>
        )}
      </div>
    </div>
  );
}
