import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import toast, { Toaster } from 'react-hot-toast';
import API from 'Services/api';
import { SubHeader } from '..';

const Index = () => {
  const [parkId, setParkId] = useState(null);
  
  useEffect(() => {
    setParkId(JSON.parse(localStorage.getItem('vendor_login_data')).parkId);
  }, [])
  
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  
  const getYears = function() {
    let startYear = 2022;
    const endYear = new Date().getFullYear(); 
    
    let years = [];
    
    for (var i = startYear; i <= endYear; i++) {
      years.push(startYear);
      startYear++;
    }
    
    return years;
  }
  
  const years = getYears();

  // Setting state for daily report date picker
  const [dailyDateRange, setDailyDateRange] = useState([
    new Date(),
    new Date()
  ]); //Daily Report
  const [startDailyDate, endDailyDate] = dailyDateRange;

  // Setting state for customer reservation date picker
  const [reservationStartDate, setReservationStartDate] = useState(new Date());
  const [reservationEndDate, setReservationEndDate] = useState(new Date());

  const [customerData, setCustomerData] = useState();
  const [customerDataFlag, setCustomerDataFlag] = useState(false);

  // Setting state for Counter Results date picker
  const [counterMonth, setCounterMonth] = useState(
    monthNames[new Date().getMonth()]
  );
  const [counterYear, setCounterYear] = useState(new Date().getFullYear());
  const [counterResultsData, setCounterResultsData] = useState();
  const [counterResultsFlag, setCounterResultsFlag] = useState(false);

  const [scanSummaryData, setScanSummaryData] = useState();
  const [scanSummaryDataFlag, setScanSummaryDataFlag] = useState(false);

  const [scanSummaryDetailsData, setScanSummaryDetailsData] = useState();
  const [scanSummaryDetailsDataFlag, setScanSummaryDetailsDataFlag] =
    useState(false);

  const [reservationDaywiseData, setReservationDaywiseData] = useState();
  const [reservationDaywiseDataFlag, setReservationDaywiseDataFlag] =
    useState(false);

  const [txDaywiseData, setTxDaywiseData] = useState();
  const [txDaywiseDataFlag, setTxDaywiseDataFlag] = useState(false);

  const [reservationMonthlyData, setReservationMonthlyData] = useState();
  const [reservationMonthlyDataFlag, setReservationMonthlyDataFlag] =
    useState(false);

  const [txMonthlyData, setTxMonthlyData] = useState();
  const [txMonthlyDataFlag, setTxMonthlyDataFlag] = useState(false);

  // onClickFunctions for API Calls
  const getCounterReport = async () => {
    toast.loading('Generating reports...');
    let month = monthNames.indexOf(counterMonth);
    try {
      const { data } = await API.get(
        `/vendor/report/counters?month=${
          month + 1
        }&year=${counterYear}&parkId=${parkId}`,
        { timeout: 0 }
      );
      if (data) {
        // setCounterResultsData(data);
        // setCounterResultsFlag(true);
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');

        a.setAttribute('href', url);

        a.setAttribute(
          'download',
          `CounterResults_${counterMonth}_${counterYear}.csv`
        );

        a.click();

        toast.dismiss();
      }
    } catch (err) {
      console.log(err);
      // toast.error(err);
      toast.dismiss();
      toast.error('Could not download report.');
    }
  };

  const getScanSummaryReport = async () => {
    toast.loading('Generating reports...');
    let month = monthNames.indexOf(counterMonth);
    try {
      const { data } = await API.get(
        '/reports/' + parkId + '/reservationScanReport',
        {
          params: {
            month: month + 1,
            year: counterYear
          }
        },
        { timeout: 0 }
      );
      if (data) {
        // setScanSummaryData(data);
        // setScanSummaryDataFlag(true);
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');

        a.setAttribute('href', url);

        a.setAttribute(
          'download',
          `ScanSummary_${counterMonth}_${counterYear}.csv`
        );

        a.click();

        toast.dismiss();
      }
    } catch (err) {
      console.log(err);
      // toast.error(err);
      toast.dismiss();
      toast.error('Could not download report.');
    }
  };

  const getScanSummaryDetailsReport = async () => {
    toast.loading('Generating reports...');
    let month = monthNames.indexOf(counterMonth);
    try {
      const { data } = await API.get(
        `/reports/${parkId}/qrScanReport`,
        {
          params: {
            month: month + 1,
            year: counterYear
          }
        },
        { timeout: 0 }
      );
      if (data) {
        // setScanSummaryDetailsData(data);
        // setScanSummaryDetailsDataFlag(true);
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');

        a.setAttribute('href', url);

        a.setAttribute(
          'download',
          `ScanSummaryDetails_${counterMonth}_${counterYear}.csv`
        );

        a.click();

        toast.dismiss();
      }
    } catch (err) {
      console.log(err);
      // toast.error(err);
      toast.dismiss();
      toast.error('Could not download report.');
    }
  };

  const getReservationDaywiseDataReport = async () => {
    toast.loading('Generating reports...');

    try {
      let start = moment(startDailyDate).format('yyyy-MM-DD');
      let end = moment(endDailyDate).format('yyyy-MM-DD');
      const { data } = await API.get(
        `/vendor/report/reservationtxndata?fromDate=${start}&toDate=${end}&parkId=${parkId}`,
        { timeout: 0 }
      );
      if (data) {
        // setReservationDaywiseData(data);
        // setReservationDaywiseDataFlag(true);
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');

        a.setAttribute('href', url);

        a.setAttribute(
          'download',
          `ReservationDataDayWise_${start}_${end}.csv`
        );

        a.click();

        toast.dismiss();
      }
    } catch (err) {
      console.log(err);
      // toast.error(err);
      toast.dismiss();
      toast.error('Could not download report.');
    }
  };

  const getTxDaywiseDataReport = async () => {
    toast.loading('Generating reports...');
    try {
      let start = moment(startDailyDate).format('yyyy-MM-DD');
      let end = moment(endDailyDate).format('yyyy-MM-DD');
      const { data } = await API.get(
        `/vendor/report/transactions?fromDate=${start}&endDate=${end}&parkId=${parkId}`,
        { timeout: 0 }
      );
      if (data) {
        // setTxDaywiseData(data);
        // setTxDaywiseDataFlag(true);
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');

        a.setAttribute('href', url);

        a.setAttribute(
          'download',
          `TransactionDataDayWise_${start}_${end}.csv`
        );

        a.click();
        toast.dismiss();
      }
    } catch (err) {
      console.error(err);
      toast.dismiss();
      toast.error('Could not download report.');
    }
  };

  const getReservationMonthlyDataReport = async () => {
    toast.loading('Generating reports...');
    try {
      let month = monthNames.indexOf(counterMonth);
      const { data } = await API.get(
        `/vendor/report/reservationtxndata?month=${
          month + 1
        }&year=${counterYear}&parkId=${parkId}`,
        { timeout: 0 }
      );
      if (data) {
        // setReservationMonthlyData(data);
        // setReservationMonthlyDataFlag(true);
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');

        a.setAttribute('href', url);

        a.setAttribute(
          'download',
          `ReservationDataMonthly_${counterMonth}_${counterYear}.csv`
        );

        a.click();

        toast.dismiss();
      }
    } catch (err) {
      console.log(err);
      // toast.error(err);
      toast.dismiss();
      toast.error('Could not download report.');
    }
  };

  const getTxMonthlyDataReport = async () => {
    toast.loading('Generating reports...');
    try {
      let month = monthNames.indexOf(counterMonth);
      const { data } = await API.get(
        `/vendor/report/transactions?month=${month + 1}&year=${counterYear}&parkId=${parkId}`,
        { timeout: 0 }
      );
      console.log(data);
      if (data) {
        // setTxMonthlyData(data);
        // setTxMonthlyDataFlag(true);
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');

        a.setAttribute('href', url);

        a.setAttribute(
          'download',
          `TransactionDataMonthly_${counterMonth}_${counterYear}.csv`
        );

        a.click();
        toast.dismiss();
      }
    } catch (err) {
      console.error(err);
      toast.dismiss();
      toast.error('Could not download report.');
    }
  };

  const getCustomerData = async () => {
    toast.loading('Generating reports...');
    try {
      const fromDate = moment(reservationStartDate).format('yyyy-MM-DD');
      const endDate = moment(reservationEndDate).format('yyyy-MM-DD');
      const { data } = await API.get(
        `/vendor/report/customerdata`,
        {
          params: {
            fromDate,
            endDate,
            parkId
          }
        },
        { timeout: 0 }
      );
      if (data) {
        // setCustomerData(data);
        // setCustomerDataFlag(true);
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');

        a.setAttribute('href', url);

        a.setAttribute(
          'download',
          `CustomerData_${fromDate}_to_${endDate}.csv`
        );

        a.click();
        toast.dismiss();
      }
    } catch (err) {
      console.log(err);
      // toast.error(err);
      toast.dismiss();
      toast.error('Could not download report.');
    }
  };

  // useEffect(() => {
  //   if (customerDataFlag) {
  //     document.getElementById('customerDataAnchor').click();
  //   }
  //   if (counterResultsFlag) {
  //     document.getElementById('counterResultsAnchor').click();
  //   }
  //   if (scanSummaryDataFlag) {
  //     document.getElementById('scanSummaryAnchor').click();
  //   }
  //   if (scanSummaryDetailsDataFlag) {
  //     document.getElementById('scanSummaryDetailsAnchor').click();
  //   }
  //   // if (reservationDaywiseDataFlag) {
  //   //   document.getElementById('reservationDaywiseDataAnchor').click();
  //   // }
  //   if (txDaywiseDataFlag) {
  //     document.getElementById('txDaywiseDataAnchor').click();
  //   }
  //   if (reservationMonthlyDataFlag) {
  //     document.getElementById('reservationMonthlyDataAnchor').click();
  //   }
  //   if (txMonthlyDataFlag) {
  //     document.getElementById('txMonthlyDataAnchor').click();
  //   }
  // }, [
  //   customerDataFlag,
  //   counterResultsFlag,
  //   scanSummaryDataFlag,
  //   scanSummaryDetailsDataFlag,
  //   // reservationDaywiseDataFlag,
  //   txDaywiseDataFlag,
  //   reservationMonthlyDataFlag,
  //   txMonthlyDataFlag,
  // ]);

  return (
    <div className='container'>
      <Toaster />
      <SubHeader />
      <div className='row row-padding-top line-bottom-dark'>
        <div className='col-md-12'>
          <h3 style={{ marginBottom: '5px' }}>
            Reservations &amp; Transactions
          </h3>
        </div>
      </div>

      {/* <form method='post'> */}
      <div className='row line-bottom'>
        <div className='col-md-2 col-sm-2 col-xs-12'>
          <p>
            <strong>
              DAILY{' '}
              <span className='Hide'>
                <br />
              </span>{' '}
              REPORT:
            </strong>
          </p>
        </div>
        <div className='col-md-4 col-sm-4 col-xs-12'>
          <label>Select Date</label>
          <div className='input-group input-daterange'>
            <DatePicker
              selectsRange={true}
              startDate={startDailyDate}
              endDate={endDailyDate}
              onChange={update => {
                setDailyDateRange(update);
              }}
            />
          </div>
        </div>
        <div className='col-md-6 col-sm-6 col-xs-12 padding-button'>
          {/* <a
              download={`ReservationDataDayWise_${counterMonth}_${counterYear}.csv`}
              className='btn btn-primary btn-outline-primary'
              onClick={() => getReservationDaywiseDataReport()}
              id='reservationDaywiseDataAnchor'
              href={
                reservationDaywiseData &&
                `data:text/csv;charset-utf-8,${encodeURIComponent(
                  reservationDaywiseData
                )}`
              }
              target='_blank noreferrer'
              style={{ marginRight: '0.4rem' }}
            >
              Export Reservations
            </a> */}
          <button
            className='btn btn-primary btn-outline-primary'
            onClick={getReservationDaywiseDataReport}
          >
            Export Reservations
          </button>

          {/* <a
            download={`TransactionDataDayWise_${counterMonth}_${counterYear}.csv`}
            className='btn btn-primary btn-outline-primary'
            onClick={() => getTxDaywiseDataReport()}
            id='txDaywiseDataAnchor'
            href={
              txDaywiseData &&
              `data:text/csv;charset-utf-8,${encodeURIComponent(txDaywiseData)}`
            }
            target='_blank noreferrer'
            style={{ marginRight: '0.4rem' }}
          >
            Export Transactions
          </a> */}
          <button
            className='btn btn-primary btn-outline-primary ml-2'
            onClick={getTxDaywiseDataReport}
          >
            Export Transactions
          </button>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2 col-sm-2 col-xs-12'>
          <p>
            <strong>MONTHLY REPORT:</strong>
          </p>
        </div>
        <div className='col-md-2 col-sm-2 col-xs-12'>
          <label htmlFor='people'>Year</label>
          <select
            className='form-control'
            id='people'
            value={counterYear}
            placeholder='Choose one...'
            onChange={e => setCounterYear(e.target.value)}
          >
            {years.map((x, i) => (
              <option key={i}>{x}</option>
            ))}
          </select>
        </div>
        <div className='col-md-2 col-sm-2 col-xs-12'>
          <label htmlFor='people'>Month</label>
          <select
            className='form-control'
            value={counterMonth}
            id='people'
            placeholder='Choose one...'
            onChange={e => setCounterMonth(e.target.value)}
          >
            {monthNames.map((x, i) => (
              <option key={i}>{x}</option>
            ))}
          </select>
        </div>
        <div className='col-md-6 col-sm-6 col-xs-12 padding-button'>
          {/* <a
            download={`ReservationDataMonthly_${counterMonth}_${counterYear}.csv`}
            className='btn btn-primary btn-outline-primary'
            onClick={() => getReservationMonthlyDataReport()}
            id='reservationMonthlyDataAnchor'
            href={
              reservationMonthlyData &&
              `data:text/csv;charset-utf-8,${encodeURIComponent(
                reservationMonthlyData
              )}`
            }
            target='_blank noreferrer'
            style={{ marginRight: '0.4rem' }}
          >
            Export Reservations
          </a> */}
          <button
            className='btn btn-primary btn-outline-primary'
            onClick={getReservationMonthlyDataReport}
          >
            Export Reservations
          </button>
          {/* <a
            download={`TransactionDataMonthly_${counterMonth}_${counterYear}.csv`}
            className='btn btn-primary btn-outline-primary'
            onClick={() => getTxMonthlyDataReport()}
            id='txMonthlyDataAnchor'
            href={
              txMonthlyData &&
              `data:text/csv;charset-utf-8,${encodeURIComponent(txMonthlyData)}`
            }
            target='_blank noreferrer'
            style={{ marginRight: '0.4rem' }}
          >
            Export Transactions
          </a> */}
          <button
            className='btn btn-primary btn-outline-primary ml-2'
            onClick={getTxMonthlyDataReport}
          >
            Export Transactions
          </button>
        </div>
      </div>

      <div className='row row-padding-top line-bottom-dark'>
        <div className='col-md-12'>
          <h3 style={{ marginBottom: '5px' }}>Scan results</h3>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2 col-sm-2 col-xs-12'>
          <label htmlFor='people'>Year</label>
          <select
            className='form-control'
            id='people'
            placeholder='Choose one...'
            value={counterYear}
            onChange={e => setCounterYear(e.target.value)}
          >
            {years.map((x, i) => (
              <option key={i}>{x}</option>
            ))}
          </select>
        </div>
        <div className='col-md-2 col-sm-2 col-xs-12'>
          <label htmlFor='people'>Month</label>
          <select
            className='form-control'
            id='people'
            placeholder='Choose one...'
            value={counterMonth}
            onChange={e => setCounterMonth(e.target.value)}
          >
            {monthNames.map((x, i) => (
              <option key={i}>{x}</option>
            ))}
          </select>
        </div>
        <div className='col-md-6 col-sm-6 col-xs-12 padding-button'>
          {/* <a
            download={`ScanSummary_${counterMonth}_${counterYear}.csv`}
            className='btn btn-primary btn-outline-primary'
            onClick={() => getScanSummaryReport()}
            id='scanSummaryAnchor'
            href={
              scanSummaryData &&
              `data:text/csv;charset-utf-8,${encodeURIComponent(
                scanSummaryData
              )}`
            }
            target='_blank noreferrer'
            style={{ marginRight: '0.4rem' }}
          >
            Export Summary
          </a> */}
          <button
            className='btn btn-primary btn-outline-primary'
            onClick={getScanSummaryReport}
          >
            Export Summary
          </button>
          {/* <a
            download={`ScanSummaryDetails_${counterMonth}_${counterYear}.csv`}
            className='btn btn-primary btn-outline-primary'
            onClick={() => getScanSummaryDetailsReport()}
            id='scanSummaryDetailsAnchor'
            href={
              scanSummaryDetailsData &&
              `data:text/csv;charset-utf-8,${encodeURIComponent(
                scanSummaryDetailsData
              )}`
            }
            target='_blank noreferrer'
          >
            Export Details
          </a> */}
          <button
            className='btn btn-primary btn-outline-primary ml-2'
            onClick={getScanSummaryDetailsReport}
          >
            Export Details
          </button>
        </div>
      </div>

      <div className='row row-padding-top line-bottom-dark'>
        <div className='col-md-12'>
          <h3 style={{ marginBottom: '5px' }}>
            Counter results (turnarounds &amp; locals)
          </h3>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-2 col-sm-2 col-xs-12'>
          <label htmlFor='people'>Year</label>
          <select
            className='form-control'
            id='people'
            placeholder='Choose one...'
            value={counterYear}
            onChange={e => setCounterYear(e.target.value)}
          >
            {years.map((x, i) => (
              <option key={i}>{x}</option>
            ))}
          </select>
        </div>
        <div className='col-md-2 col-sm-2 col-xs-12'>
          <label htmlFor='people'>Month</label>
          <select
            className='form-control'
            value={counterMonth}
            id='people'
            placeholder='Choose one...'
            onChange={e => setCounterMonth(e.target.value)}
          >
            {monthNames.map((x, i) => (
              <option key={i}>{x}</option>
            ))}
          </select>
        </div>
        <div className='col-md-6 col-sm-6 col-xs-12 padding-button'>
          {/* <a
            download={`CounterResults_${counterMonth}_${counterYear}.csv`}
            className='btn btn-primary btn-outline-primary'
            onClick={() => getCounterReport()}
            id='counterResultsAnchor'
            href={
              counterResultsData &&
              `data:text/csv;charset-utf-8,${encodeURIComponent(
                counterResultsData
              )}`
            }
            target='_blank noreferrer'
          >
            Export
          </a> */}
          <button
            className='btn btn-primary btn-outline-primary'
            onClick={getCounterReport}
          >
            Export
          </button>
        </div>
      </div>

      <div className='row row-padding-top line-bottom-dark'>
        <div className='col-md-12'>
          <h3 style={{ marginBottom: '5px' }}>Customer data</h3>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 col-sm-4 col-xs-12'>
          <label htmlFor='people'>Reservation Range</label>

          <div className='input-group input-daterange'>
            <DatePicker
              dateFormat='yyyy/MM/dd'
              selectsRange
              startDate={reservationStartDate}
              endDate={reservationEndDate}
              onChange={dates => {
                setReservationStartDate(dates[0]);
                setReservationEndDate(dates[1]);
              }}
            />
          </div>
        </div>
        <div className='col-md-6 col-sm-6 col-xs-12 padding-button'>
          {/* <a
            download={`CustomerData_${moment(reservationStartDate).format(
              'yyyy-MM-DD'
            )}_to_${moment(reservationEndDate).format('yyyy-MM-DD')}.csv`}
            type='button'
            className='btn btn-primary btn-outline-primary'
            onClick={() => getCustomerData()}
            id='customerDataAnchor'
            href={
              customerData &&
              `data:text/csv;charset-utf-8,${encodeURIComponent(customerData)}`
            }
            target='_blank noreferrer'
          >
            Export
          </a> */}
          <button
            className='btn btn-primary btn-outline-primary'
            onClick={getCustomerData}
          >
            Export
          </button>
        </div>
      </div>
      {/* </form> */}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default Index;
