import { useRouter } from "next/router";
import { createContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import API from "Services/api";

const TimerContext = createContext();

// testing husky
const TimerProvider = ({ children }) => {
  const Ref = useRef(null);
  const router = useRouter();

  const [timer, setTimer] = useState("");
  const [expirePopup, setExpirePopup] = useState(false);
  const [extendPopup, setExtendPopup] = useState(false);

  // convert the localStorage string to UI string
  const localToString = () => {
    let local = localStorage.getItem("timer");

    if (!local) {
      local = 900;
      localStorage.setItem("timer", 900);
    } else local = parseInt(local);

    let min = Math.floor(local / 60);
    let sec = local % 60;
    sec = sec < 10 ? "0" + sec : sec;
    min = min < 10 ? "0" + min : min;
    let str = min + ":" + sec;
    return str;
  };

  // ui string to localStorage
  const stringToLocal = () => {
    if (!!timer && timer !== "00:00") {
      const t = timer.split(":");
      localStorage.setItem("timer", parseInt(t[0]) * 60 + parseInt(t[1]));
    }

    if (timer === "00:00") {
      localStorage.setItem("timer", 900);
    }
  };

  // get the remaining time
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  // set UI timer value
  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  // reset timer
  const clearTimer = (e) => {
    if (!Ref.current) {
      setTimer(localToString());

      if (Ref.current) clearInterval(Ref.current);

      const id = setInterval(() => {
        startTimer(e);
      }, 1000);

      Ref.current = id;
    } else {
      console.log("Context timer already running...", Ref.current);
    }
  };

  const stopTimer = () => {
    clearInterval(Ref.current);
    Ref.current = null;
    setTimer("");
    localStorage.setItem("timer", 900);
  };

  // helper
  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(
      deadline.getSeconds() + parseInt(localStorage.getItem("timer"))
    );
    return deadline;
  };

  // remove the comments to start it automatically
  // asautomatically start the timer when there is already a value in localStorge
  useEffect(() => {
    if (
      !!localStorage.getItem("timer") &&
      localStorage.getItem("timer") !== "900" &&
      localStorage.getItem("timer") !== "900"
    )
      clearTimer(getDeadTime());
  }, []);

  // useEffect(() => {
  //   const unloadCallback = e => {
  //     stopTimer();
  //     e.preventDefault();
  //     e.returnValue = '';
  //     return '';
  //   };

  //   window.addEventListener('beforeunload', unloadCallback);

  //   return () => window.removeEventListener('beforeunload', unloadCallback);
  // }, []);

  useEffect(() => {
    const timer = localStorage.getItem("timer");

    if (!timer || isNaN(timer)) {
      localStorage.setItem("timer", 900);
    } else {
      // const t = timer.split(':');
      // localStorage.setItem('timer', parseInt(t[0]) * 60 + parseInt(t[1]));
    }
  }, []);

  useEffect(() => {
    if (timer === "01:00") {
      setExtendPopup(true);
    }

    if (timer === "00:00") {
      // add the local storage for PUC here
      // localStorage.removeItem('booking_data');
      // localStorage.removeItem('customer_input');
      // localStorage.removeItem('bookingDetails');

      clearInterval(Ref.current);
      Ref.current = null;

      localStorage.setItem("timer", 900);

      setExtendPopup(false);
      setExpirePopup(true);

      console.log(Ref.current);
    }
  }, [timer]);

  useEffect(() => {
    stringToLocal();
  });

  const handleCartReset = () => {
    setExpirePopup(false);
    router.push("/");
  };

  const handleExtendTimer = async () => {
    try {
      let updateData = localStorage.getItem("updated_reservation_data");

      const reqID = JSON.parse(updateData).reqID;

      await API.put("puc/extendcart", { reqID });

      console.log(Ref.current);

      clearInterval(Ref.current);
      Ref.current = null;

      let time = parseInt(localStorage.getItem("timer"));

      time = time + 300;

      localStorage.setItem("timer", time);

      clearTimer(getDeadTime());

      setExtendPopup(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <TimerContext.Provider
      value={{
        getDeadTime,
        clearTimer,
        startTimer,
        timer,
        setTimer,
        stopTimer,
      }}
    >
      {children}

      <Modal show={expirePopup}>
        <Modal.Body>
          <h3>Cart Expired!</h3>
          <p>Your cart has expired. Please start over again.</p>
          <br />
          <button
            className="btn btn-primary"
            onClick={() => handleCartReset()}
            style={{ marginBottom: "20px" }}
          >
            Go to Home
          </button>
        </Modal.Body>
      </Modal>

      <Modal show={extendPopup}>
        <Modal.Body>
          <h3>Extend Timer</h3>
          <p>Cart will expire in {timer}, do you want to add 5 more minutes?</p>
          <br />
          <button
            className="btn btn-primary"
            onClick={() => handleExtendTimer()}
            style={{ marginBottom: "20px" }}
          >
            Extend Timer
          </button>
        </Modal.Body>
      </Modal>
    </TimerContext.Provider>
  );
};

export { TimerContext, TimerProvider };
