import FullCalendar from '@fullcalendar/react';
// dayGridPlugin must import after fullcalendar/react
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import API from 'Services/api';
import { SubHeader } from '..';

const Index = () => {
  const router = useRouter();
  const [currDate, setCurrDate] = useState(new Date());
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState([]);
  
  const [parkId, setParkId] = useState(null);
  
  useEffect(() => {
    setParkId(JSON.parse(localStorage.getItem('vendor_login_data')).parkId);
    
    const apiHit = async () => {
      const { data } = await API.get('/vendor/calenderview', {
        params: {
          month: moment(currDate).format('M'),
          year: moment(currDate).format('YYYY'),
          parkId: JSON.parse(localStorage.getItem('vendor_login_data')).parkId
        },
      });
      console.log(data?.data);
      if (data.message === 'Success') {
        const newData = [];
        const {
          reservationDataToReturn,
          transactionDataToReturn,
          blockDataToReturn,
        } = data?.data;

        for (const block in blockDataToReturn) {
          blockDataToReturn[block] === 1
            ? newData.push({
                title: 'Block',
                start: block,
                className: 'block_class',
                groupId: 'block',
                allDay: true,
              })
            : newData.push({
                title: 'Unblock',
                start: block,
                groupId: 'unblock',
                className: 'unblock_class',
                allDay: true,
              });
        }

        for (const reserveDate in reservationDataToReturn) {
          newData.push({
            title: `View Reservations (${reservationDataToReturn[reserveDate]})`,
            start: reserveDate,
            className: 'reservation_class',
            groupId: 'reservationGroup',
            textColor: '#233731',
            allDay: true,
          });
        }
        for (const transaction in transactionDataToReturn) {
          newData.push({
            title: `View Transactions (${transactionDataToReturn[transaction]})`,
            start: transaction,
            className: 'reservation_class',
            groupId: 'transactionGroup',
            textColor: '#233731',
            allDay: true,
          });
        }
        setData(newData);
      }
    };

    apiHit();
  }, [currDate, flag]);

  const getCurrDate = (dateInfo) => {
    const getDate = dateInfo.start;
    
    setCurrDate(getDate);
  };
  // console.log(data);

  const handleBlock = async (currEvent) => {
    try {
      const { data } = await API.post('/vendor/block', {
        date: moment(currEvent.start).format('YYYY-MM-DD'),
        status: currEvent.groupId,
        parkId: parkId,
      });
      // console.log(data);
      if (data?.message === 'Success') {
        setFlag(!flag);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleEvents = (eventInfo) => {
    const currEvent = eventInfo.event;
    const clickedDate = eventInfo.event.startStr;
    if (currEvent.groupId === 'reservationGroup') {
      localStorage.setItem(
        'reservationSearch',
        JSON.stringify({
          transactionDate: moment(clickedDate).format('YYYY-MM-DD'),
          dateOfReservation: moment(clickedDate).format('YYYY-MM-DD'),
          endDate: moment(clickedDate).format('YYYY-MM-DD'),
        }),
      );
      router.push('/search-results');
    }

    if (currEvent.groupId === 'transactionGroup') {
      localStorage.setItem(
        'reservationSearch',
        JSON.stringify({
          transactionDate: moment(clickedDate).format('YYYY-MM-DD'),
          dateOfReservation: moment(clickedDate).format('YYYY-MM-DD'),
          fromDate: moment(clickedDate).format('YYYY-MM-DD'),
          endDate: moment(clickedDate).format('YYYY-MM-DD'),
        }),
      );
      window.location.href = '/search-results?tx=true';
    }

    if (currEvent.groupId === 'block' || currEvent.groupId === 'unblock') {
      handleBlock(currEvent);
    }
  };

  return (
    <>
      <div className='container'>
        <SubHeader />

        <div className='row row-padding'>
          <div className='col-xl-6 col-md-6'>
            <h2 style={{ margin: '.5rem 0' }}>
              Active reservations: calendar view
            </h2>
          </div>
          <div className='col-xl-6 col-md-6 text-end'>
            <a
              className='btn btn-primary btn-outline-primary'
              href='dashboard'
              style={{ marginRight: '0.4rem' }}
            >
              Search Reservations
            </a>
            <a className='btn btn-primary btn-outline-primary' href='list-view'>
              List view
            </a>
          </div>
        </div>

        <div className='row line-bottom-dark g-3'>
          <div className='col-xl-12 col-md-12 text-end'>
            <a className='btn btn-primary' href='calendar-view'>
              Export Reservations
            </a>
          </div>
        </div>

        <div className='row line-bottom row-padding-bottom'>
          <div className='col-12'>
            <div id='wrap'>
              {/* <a href='search-results.html'>
                <div id='calendar'></div>
                <div style='clear:both'></div>
              </a> */}

              <FullCalendar
                headerToolbar={{
                  left: 'title',
                  center: 'prev,next',
                  right: 'today',
                }}
                plugins={[dayGridPlugin]}
                initialView='dayGridMonth'
                events={data}
                eventClick={handleEvents}
                showNonCurrentDates={false}
                datesSet={getCurrDate}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
