import React from 'react';

import '@fullcalendar/common/main.css'; // @fullcalendar/react imports @fullcalendar/common
import '@fullcalendar/daygrid/main.css'; // @fullcalendar/timegrid imports @fullcalendar/daygrid

import 'Styles/globals.css';
import '../../public/css/bootstrap.min.css';
import '../../public/css/hawaii.css';
import '../../public/css/fullcalendar.css';
import '../../public/css/fullcalendar.print.css';
import '../../public/css/datepicker.css';
import { Layout } from 'Molecules/';
import { GlobalProvider } from 'Lib/contexts/GlobalContext';
import { TimerProvider } from 'Lib/contexts/TimerContext';

import Script from "next/script";
import { Config } from 'Lib/config';

function MyApp({ Component, pageProps }) {
  return (
    <>
    
    <Script id="freshChat" strategy="afterInteractive">
      {`
        window.fcWidget.init({
            token: '${Config.FRESHCHAT_TOKEN}',
            host: 'https://wchat.freshchat.com',
            siteId: '${Config.FRESHCHAT_ID}',
            config: {
              hideFAQ: true,
            }
        });
      `}
    </Script>
    
    <GlobalProvider>
      <TimerProvider>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </TimerProvider>
    </GlobalProvider>
    </>
  );
}

export default MyApp;
