import React from 'react';

const PaymentLoader = () => {
  return (
    <div className='loader-container'>
      <div className='spinner-grow loader-main' role='status'>
        <span className='sr-only'>Loading...</span>
      </div>
      <div className='loader-content'>
        <h3>Verification in progress...</h3>
        <h6>We are now processing your payment. Almost done!</h6>
      </div>
    </div>
  );
};

export default PaymentLoader;
