import { Loader } from 'Atoms/';
import { CapacityContext } from 'Lib/contexts/GlobalContext';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import API from 'Services/api';
import { STATIC_PARK_ID } from 'src/constants/misc';
import { SubHeader } from '..';

const Index = () => {
  const [updateCap, setUpdateCap] = useState(null);
  const [show, setShow] = useState(false);
  const router = useRouter();
  const { capacities, setCapacities } = useContext(CapacityContext);
  
  const [parkId, setParkId] = useState(null);

  useEffect(() => {
    setParkId(JSON.parse(localStorage.getItem('vendor_login_data')).parkId);
    
    if (capacities) {
      setUpdateCap(capacities);
      return;
    }
    router.push('/settings');
  }, [capacities]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const transformPayload = (updatePayload) => {
    const refactoredTimeslots = updatePayload.timeSlotsCapacity.map((slot) => {
      if (slot.updateCapacity === '') {
        slot.updateCapacity = slot.currentCapacity;
      }
      delete slot.currentCapacity;
      return slot;
    });
    return { ...updatePayload, timeSlotsCapacity: refactoredTimeslots };
  };

  const handleUpdate = async () => {
    try {
      const body = transformPayload(updateCap);
      const { data } = await API.put(
        `/parks/${parkId}/capacity/`,
        body,
      );
      // console.log(data);
      localStorage.removeItem('updateCapacity');
      // toast.success(
      //   `Updated capacities for ${moment(effectiveDate).format('YYYY-MM-DD')}!`
      // );
      router.push('/settings');
    } catch (err) {
      toast.error('Something went wrong');
      console.log(err);
    }
  };

  if (!updateCap) return <Loader />;

  return (
    <div className='container'>
      <SubHeader />
      <Toaster />
      <div className='row row-padding-top line-bottom-dark'>
        <div className='col-12'>
          <h2>Confirm capacity changes</h2>
        </div>
      </div>

      <div className='row row-padding-top line-bottom-dark'>
        <div className='col-12'>
          <p>
            <strong>Effective date {updateCap.effectiveDate}:</strong> One date
            only in effective date
          </p>
        </div>
      </div>

      <div className='row line-bottom'>
        <div className='col-xl-4 col-md-3 col-4'>
          <p className='less-padding'>
            <strong>Ticket</strong>
          </p>
        </div>
        <div className='col-xl-4 col-md-3 col-4'>
          <p className='less-padding'>
            <strong>Timeslot</strong>
          </p>
        </div>
        <div className='col-xl-4 col-md-3 col-4'>
          <p className='less-padding'>
            <strong>New capacity</strong>
          </p>
        </div>
      </div>

      {updateCap?.timeSlotsCapacity?.map((item, idx) => (
        <div className='row line-bottom' key={idx}>
          <div className='col-xl-4 col-md-3 col-4'>
            <p className='less-padding' style={{ textTransform: 'capitalize' }}>
              {item.ticket}
            </p>
          </div>
          <div className='col-xl-4 col-md-3 col-4'>
            <p className='less-padding'>
              {item.startTime} - {item.endTime}
            </p>
          </div>
          <div className='col-xl-4 col-md-3 col-4'>
            <p className='less-padding'>
              {item.updateCapacity === ''
                ? Number(item.currentCapacity)
                : Number(item.updateCapacity)}
            </p>
          </div>
        </div>
      ))}

      <div className='row row-padding-bottom'>
        <div className='col-md-12 text-end' style={{ paddingTop: '15px' }}>
          <Link href='/settings'>
            <a className='btn btn-primary btn-outline-primary'>Go back</a>
          </Link>
          <button className='btn btn-primary ml-2' onClick={handleShow}>
            Confirm
          </button>

          {/* <div
            className='modal fade'
            id='exampleModal'
            tabindex='-1'
            role='dialog'
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
          >
            <div
              className='modal-dialog modal-dialog-centered modal-dialog-scrollable'
              role='document'
            >
              <div className='modal-content'>
                <div className='modal-header text-start'>
                  <p>Would you like to confirm the update?</p>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-primary btn-outline-primary'
                  >
                    Cancel
                  </button>{' '}
                  <a className='btn btn-primary' href='settings.html'>
                    Confirm
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Body>
              <p>Would you like to confirm the update?</p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className='btn btn-primary btn-outline-primary'
                onClick={handleClose}
              >
                Cancel
              </button>
              <button className='btn btn-primary' onClick={handleUpdate}>
                Confirm
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Index;
