import * as Yup from 'yup';

const PUCPaymentSchema = Yup.object({
  creditCardValue: Yup.string().required('Required'),
  creditCardType: Yup.string().required('Required'),
  expDate: Yup.string().required('Required'),
  cvv: Yup.string().required('Required'),
  firstName: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  lastName: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
  mobile: Yup.string().required('Required'),
});

export default PUCPaymentSchema;
