import Link from 'next/link';
import { useRouter } from 'next/router';

const Index = () => {
  const router = useRouter();
  return (
    <>
      <div className='row row-padding-top'>
        <div className='col-12'>
          <ul className='nav nav-tabs'>
            <li className='nav-item'>
              <Link href='/dashboard'>
                <a
                  className={
                    router.pathname == '/dashboard' ||
                    router.pathname == '/list-view' ||
                    router.pathname == '/calendar-view' ||
                    router.pathname == '/search-results'
                      ? 'nav-link active'
                      : 'nav-link'
                  }
                  aria-current='page'
                >
                  Reservations
                </a>
              </Link>
            </li>
            <li className='nav-item'>
              <Link href='/reports'>
                <a
                  className={
                    router.pathname == '/reports'
                      ? 'nav-link active'
                      : 'nav-link'
                  }
                >
                  Reports
                </a>
              </Link>
            </li>
            <li className='nav-item'>
              <Link href='/settings'>
                <a
                  className={
                    router.pathname == '/settings' ||
                    router.pathname == '/update-capacity'
                      ? 'nav-link active'
                      : 'nav-link'
                  }
                >
                  Settings
                </a>
              </Link>
            </li>
            <li className='nav-item'>
              <Link href='/account'>
                <a
                  className={
                    router.pathname == '/account'
                      ? 'nav-link active'
                      : 'nav-link'
                  }
                >
                  Account
                </a>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Index;
