import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import TextError from '../TextError';

const MyPhoneInput = ({ name, formikObj, style }) => {
  const { values, handleBlur, touched, errors, setFieldValue } = formikObj;
  return (
    <>
      <PhoneInput
        international
        name={name}
        defaultCountry='US'
        initialValueFormat='national'
        value={values.mobile}
        onBlur={handleBlur}
        onChange={(e) => setFieldValue([name], e)}
        style={{ border: '1px solid #447969', ...style }}
      />
      {touched.mobile && errors.mobile && (
        <TextError>{errors.mobile}</TextError>
      )}
    </>
  );
};

export default MyPhoneInput;
