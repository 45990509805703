import { Loader, PaymentLoader } from 'Atoms/';
import { TimerContext } from 'Lib/contexts/TimerContext';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import API from 'Services/api';
import Timer from 'src/components/Timer';
import { SubHeader } from '..';
import Payment from './Payment';

const Index = () => {
  const router = useRouter();
  const { stopTimer } = useContext(TimerContext);
  const [updatedDetails, setUpdatedDetails] = useState('');
  const [customerDetails, setCustomerDetails] = useState('');
  const [paymentDetails, setPaymentDetails] = useState('');
  const [updatedData, setUpdatedData] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [show, setShow] = useState(false);
  console.log('updatedData', updatedData);
  console.log('updatedDetails', updatedDetails);
  useEffect(() => {
    if (localStorage.getItem('updated_reservation_data')) {
      setUpdatedDetails(
        JSON.parse(localStorage.getItem('updated_reservation_data'))
      );
      setCustomerDetails(JSON.parse(localStorage.getItem('customer_data')));
      setPaymentDetails(JSON.parse(localStorage.getItem('pricing_data')));
      setUpdatedData(JSON.parse(localStorage.getItem('updated_data')));
    }
  }, []);

  if (!updatedDetails || !customerDetails || !paymentDetails || !updatedData) {
    return <Loader />;
  }
  const { reservationId } = updatedData;
  console.log(reservationId);
  const { takePayment, amountToCharge, updatedReservationDetails } =
    updatedDetails;
  console.log('amountToCharge', amountToCharge);
  const { pricingDetails: OldBookingDetails, total } = paymentDetails;

  const lastObj =
    updatedReservationDetails[updatedReservationDetails.length - 1];
  const { ticket: ticket_type, price } = lastObj;
  const {
    firstName,
    lastName,
    email,
    mobile,
    address,
    country,
    city,
    stateProvince,
    zip,
    orderNo
  } = customerDetails;
  
  /*const handlePay = async () => {
    handleShow();
    setPaymentLoading(true);
    
    const updateData = localStorage.getItem('updated_reservation_data');
    const reqID = JSON.parse(updateData).reqID;
    const obj = {
      ...updatedData,
      dataValue: '-',
      dataDescriptor: '-',
      customerData: {
        firstName,
        lastName,
        email,
        country,
        address,
        city,
        stateProvince,
        zip,
        mobile
      },
      origin: 'vendor',
      reqID
    };
    
    try {
      const { data } = await API.post('vendor/reservations/update/visitor', obj);
      console.log('data', data);
      if (data.message === 'Success') {
        localStorage.removeItem('customer_data');
        localStorage.removeItem('reservation_data');
        localStorage.setItem('confirmed_data', JSON.stringify(data?.data));
        stopTimer();
        //router.push('/change-confirmation');
        
        router.push(data.data.checkoutUrl);
      }
    } catch (error) {
      console.log(error);
    }
  }*/
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleConfirmChanges = async () => {
    const updateData = localStorage.getItem('updated_reservation_data');
    const reqID = JSON.parse(updateData).reqID;
    const obj = {
      ...updatedData,
      dataValue: '-',
      dataDescriptor: '-',
      customerData: {
        firstName,
        lastName,
        email,
        country,
        address,
        city,
        stateProvince,
        zip,
        mobile
      },
      origin: 'vendor',
      reqID
    };
    try {
      const { data } = await API.post('vendor/reservations/update/visitor', obj);
      console.log('data', data);
      if (data.message === 'Success') {
        localStorage.removeItem('customer_data');
        localStorage.removeItem('reservation_data');
        localStorage.setItem('confirmed_data', JSON.stringify(data?.data));
        stopTimer();
        router.push('/change-confirmation');
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className='container'>
      <SubHeader />
      <div className='row row-padding'>
        <div className='col-md-8 col-sm-8 col-xs-12'>
          <h1>Modified reservation details</h1>
        </div>
        <Timer />
        <div className='col-md-12'>
          <p>
            Please remember, once payment has been completed, orders can no
            longer be cancelled, so please review your items and pickup time
            carefully. Mahalo!
          </p>
        </div>
      </div>
      <div className='row line-bottom highlight'>
        <div className='col-xl-3 col-md-6 col-6'>
          <p style={{ fontWeight: 'bold' }} className='less-padding'>
            Original reservation details
          </p>
        </div>
      </div>
      <div className='row line-bottom'>
        <div className='col-xl-2 col-md-2 col-2'>
          <p className='less-padding'>
            <strong>Ticket</strong>
          </p>
        </div>
        <div className='col-xl-1 col-2'>
          <p className='less-padding'>
            <strong>Qty</strong>
          </p>
        </div>
        <div className='col-xl-2 col-md-2 col-3'>
          <p className='less-padding'>
            <strong>Date</strong>
          </p>
        </div>
        <div className='col-xl-3 col-2'>
          <p className='less-padding'>
            <strong>Time</strong>
          </p>
        </div>
        <div className='col-1 text-end'>
          <p className='less-padding'>
            <strong>Price</strong>
          </p>
        </div>
      </div>
      {OldBookingDetails?.map(({ ticket, qty, date, time, price }) => (
        <div className='row line-bottom'>
          <div className='col-xl-2 col-md-2 col-2'>
            <p className='less-padding'>{ticket}</p>
          </div>
          <div className='col-xl-1 col-2'>
            <p className='less-padding'>{qty}</p>
          </div>
          <div className='col-xl-2 col-md-2 col-3'>
            <p className='less-padding'>{date}</p>
          </div>
          <div className='col-xl-3 col-2'>
            <p className='less-padding'>{time}</p>
          </div>
          <div className='col-1 text-end'>
            <p className='less-padding'>${price}</p>
          </div>
        </div>
      ))}
      <div className='row line-bottom'>
        <div className='col-xl-2 col-md-2 col-2'>
          <p className='less-padding'>Original Charge</p>
        </div>
        <div className='col-xl-1 col-2'>
          <p className='less-padding'></p>
        </div>
        <div className='col-xl-2 col-md-2 col-3'>
          <p className='less-padding'></p>
        </div>
        <div className='col-xl-3 col-2'>
          <p className='less-padding'></p>
        </div>
        <div className='col-1 text-end'>
          <p className='less-padding'>${total}</p>
        </div>
      </div>
      <div className='row line-bottom highlight'>
        <div className='col-xl-3 col-md-6 col-6'>
          <p style={{ fontWeight: 'bold' }} className='less-padding'>
            Updated reservation details
          </p>
        </div>
      </div>
      <div className='row line-bottom'>
        <div className='col-xl-2 col-md-2 col-2'>
          <p className='less-padding'>
            <strong>Ticket</strong>
          </p>
        </div>
        <div className='col-xl-1 col-2'>
          <p className='less-padding'>
            <strong>Qty</strong>
          </p>
        </div>
        <div className='col-xl-2 col-md-2 col-3'>
          <p className='less-padding'>
            <strong>Date</strong>
          </p>
        </div>
        <div className='col-xl-3 col-2'>
          <p className='less-padding'>
            <strong>Time</strong>
          </p>
        </div>
        <div className='col-1 text-end'>
          <p className='less-padding'>
            <strong>Price</strong>
          </p>
        </div>
      </div>
      {updatedReservationDetails?.map(
        ({ ticket, qty, date, time, price }, index) =>
          index !== updatedReservationDetails.length - 1 && (
            <div className='row line-bottom'>
              <div className='col-xl-2 col-md-2 col-2'>
                <p className='less-padding'>{ticket}</p>
              </div>
              <div className='col-xl-1 col-2'>
                <p className='less-padding'>{qty}</p>
              </div>
              <div className='col-xl-2 col-md-2 col-3'>
                <p className='less-padding'>{date}</p>
              </div>
              <div className='col-xl-3 col-2'>
                <p className='less-padding'>{time}</p>
              </div>
              <div className='col-1 text-end'>
                {price === 'Waived' ? (
                  <p className='less-padding'>{price}</p>
                ) : (
                  <p className='less-padding'>${price}</p>
                )}
              </div>
            </div>
          )
      )}

      <div className='row line-bottom highlight'>
        <div className='col-3'>
          <p style={{ fontWeight: 'bold' }} className='less-padding'>
            {ticket_type}
          </p>
        </div>
        <div className='col-xl-2 col-md-2 col-3'>
          <p className='less-padding'></p>
        </div>
        <div className='col-xl-3 col-3'>
          <p className='less-padding'></p>
        </div>
        <div className='col-1 text-end'>
          <p className='less-padding'>${price}</p>
        </div>
        <div className='col-3 '>
          <p className='less-padding'></p>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-6 col-12'>
          <p className='less-padding' style={{ paddingTop: '.2rem' }}>
            <strong>Customer details:</strong>
          </p>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-2 col-4'>
          <p className='less-padding'>Order #:</p>
        </div>
        <div className='col-xl-5 col-8'>
          <p className='less-padding'>{orderNo}</p>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-2 col-4'>
          <p className='less-padding'>Your name:</p>
        </div>
        <div className='col-xl-5 col-8'>
          <p className='less-padding'>
            {firstName} {lastName}
          </p>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-2 col-4'>
          <p className='less-padding'>Email:</p>
        </div>
        <div className='col-xl-5 col-8'>
          <p className='less-padding'>{email}</p>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-2 col-4'>
          <p className='less-padding'>Address:</p>
        </div>
        <div className='col-xl-5 col-8'>
          <p className='less-padding'>
            {address}
            <br />
            {city}, {stateProvince} {zip} {country}
          </p>
        </div>
      </div>

      <div className='row'>
        <div className='col-xl-2 col-4'>
          <p className='less-padding'>Telephone:</p>
        </div>
        <div className='col-xl-5 col-8'>
          <p className='less-padding'>{mobile}</p>
        </div>
      </div>
      {amountToCharge && (
        <div>
          <div className='row'>
            <div className='col-xl-2 col-4'>
              <p className='less-padding'>Additional charge:</p>
            </div>
            <div className='col-xl-5 col-8'>
              <p className='less-padding'>${amountToCharge}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-2 col-4'>
              <p className='less-padding'>Fee:</p>
            </div>
            <div className='col-xl-5 col-8'>
              <p className='less-padding'>${updatedDetails.addOn}</p>
            </div>
          </div>
          <div className='row line-bottom'>
            <div className='col-xl-2 col-4'>
              <p className='less-padding'><strong>Total:</strong></p>
            </div>
            <div className='col-xl-5 col-8'>
              <p className='less-padding'><strong>${parseFloat(amountToCharge) + parseFloat(updatedDetails.addOn)}</strong></p>
            </div>
          </div>
        </div>
      )}

      <div className='row'>
        <div className='col-xl-12 col-12'>
          <p>
            <span className='red'>
              Partial refunds will be offered if cancelled more than seven (3)
              days in advance.{' '}
            </span>
            Modifications will be considered for parking and/or entry
            reservation times, on a space-available basis and done at least 3
            days in advance (additional fees may apply, partial refunds may or
            may not apply).
            <br />
            <br />
            <span className='red'>We are not responsible </span>
            for last-minute closures of the park due to weather, safety
            concerns, road closures, or trail closures from any cause
            whatsoever. Full or partial refunds may be considered but will be at
            the sole discretion of the Hawaii State Parks Division.
          </p>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='checkbox'
              value={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              id='flexCheckDefault'
            />
            <label className='form-check-label' for='flexCheckDefault'>
              Please check here that you agree with all terms listed above.
            </label>
          </div>
        </div>
      </div>

      <div className='row row-padding-top'>
        <div className='col-md-12'>
          <Link href={`/change-reservation/${reservationId}`}>
            <a className='btn btn-primary btn-outline-primary'>Cancel</a>
          </Link>
          {!takePayment && (
            <button
              disabled={!isChecked}
              className='btn btn-primary ml-1'
              onClick={handleConfirmChanges}
              style={{ background: '#447969' }}
            >
              Confirm changes
            </button>
          )}
          {takePayment && (
            <button
              disabled={!isChecked}
              type='button'
              className='btn btn-primary ml-1'
              onClick={handleShow}
              style={{ background: '#447969' }}
            >
              Confirm & pay
            </button>
          )}
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header> </Modal.Header>
            <Modal.Body>
              {paymentLoading ? (
                <PaymentLoader />
              ) : amountToCharge ? (
                <div>
                  You cannot update this reservation. The change you requested
                  requires additional payment. Please ask the customer to update
                  and make the payment.
                </div>
              ) : (
                <Payment
                  setPaymentLoading={setPaymentLoading}
                  handleClose={handleClose}
                  grandTotal={amountToCharge}
                />
              )}
            </Modal.Body>
            {amountToCharge && (   
              <Modal.Footer>        
                <button className='btn btn-primary' onClick={handleClose}>       
                  Okay        
                </button>          
              </Modal.Footer>           
            )}
          </Modal>
        </div>
      </div>

      <br />
      <br />
      <br />
    </div>
  );
};

export default Index;
