import * as Yup from 'yup';

const PUCBillingInfoSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  zip: Yup.string().required('Required'),
  mobile: Yup.string().required('Required'),
});

export default PUCBillingInfoSchema;
