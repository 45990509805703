import React, { useContext, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { MySelect, MyTextInput } from 'Atoms/Formik';
import { useRouter } from 'next/router';
import API from 'Services/api';
import toast, { Toaster } from 'react-hot-toast';
import { PUCLoginSchema } from 'Lib/validators';
import { Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { UserContext } from 'Lib/contexts/GlobalContext';

const Index = () => {
  const router = useRouter();
  const [parks, setParks] = useState([]);
  const [show, setShow] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const { setIsLogin } = useContext(UserContext);
  var initialValues = {
    email: '',
    password: '',
    park: '',
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const { data } = await API.post(`/vendor/${forgotEmail}/forgotPassword`, {
        hostName: `${window.location.protocol}//${window.location.host}`,
      });
      console.log(data);
      if (data?.message === 'Success') {
        toast.success('Password reset email sent successfully');
        setForgotEmail('');
        handleClose();
      }
    } catch (error) {
      toast.error("Something went wrong! Make sure you're registered.");
    }
  };
  
  useEffect(() => {
    async function apiHit() {
      try {
        const { data } = await API.get("/parks/listManaged");
        if (data?.message === "Success") {
          setParks(data?.data?.parks);
        }
      } catch (error) {
        console.log(error);
      }
    }
    apiHit();
  }, []);

  return (
    <>
      <div className='container'>
        <div>
          <Toaster />
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={PUCLoginSchema}
          onSubmit={async (values) => {
            try {
              const { data } = await API.post('/vendor/login', {
                email: values.email,
                password: values.password,
                park: values.park
              });
              if (data?.message === 'Successfully logged in.') {
                localStorage.removeItem('vendor_login_data');
                localStorage.setItem(
                  'vendor_login_data',
                  JSON.stringify(data?.data)
                );
                
                const parkData = await API.get("/parks/data", {
                  params: { parkId: values.park },
                });
                
                if (parkData.data.message === "Success") {
                  localStorage.setItem('parkData', JSON.stringify(parkData.data.data));
                }
                
                Cookies.set('token', data?.data?.jwtToken, { expires: 1 });
                Cookies.set('userId', data?.data?.userId, { expires: 1 });
                Cookies.set('vendorSuperUser', data?.data?.vendorSuperUser, { expires: 1 });
                setIsLogin(true);
                toast.success('Login Successful');
                router.push('/dashboard');
              }
            } catch (error) {
              toast.error(error.response.data.error);
            }
          }}
        >
          {(props) => {
            return (
              <Form>
                <div className='row row-padding-top'>
                  <div className='col-xl-6 col-md-6'>
                    <h2>Login:</h2>

                    <div className='row' style={{ paddingTop: '15px' }}>
                      <div className='col-xl-3 col-3'>
                        <label htmlFor='people'>Email</label>
                      </div>
                      <div className='col-xl-9 col-9'>
                        <MyTextInput
                          name='email'
                          type='email'
                          className='form-control'
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-3 col-3'>
                        <label htmlFor='people'>Password</label>
                      </div>
                      <div className='col-xl-9 col-9'>
                        <MyTextInput
                          name='password'
                          type='password'
                          className='form-control'
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-xl-3 col-3'>
                        <label htmlFor='people'>Select park</label>
                      </div>
                      <div className='col-xl-9 col-9'>
                        <MySelect
                          name='park'
                          className='form-control'
                          placeholder='Choose one...'
                        >
                          <option value="">-- Select Park --</option>
                          {parks?.map((park) => (
                            <option value={park._id} key={park._id}>{park.parkName}</option>
                          ))}
                          {/*<option value='AL'>
                            Diamond Head State Monument
                          </option>*/}
                        </MySelect>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: '40px' }}>
                  <button type='submit' className='btn btn-primary'>
                    Login
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
        <div className='row'>
          <div className='col-md-6 col-sm-6 col-xs-12'>
            <p>
              Forgot your{' '}
              <span className='forgotpassword-container' onClick={handleShow}>
                password
              </span>
              ?
            </p>

            <Modal show={show} onHide={handleClose}>
              <form onSubmit={handleForgotPassword}>
                <Modal.Header>
                  <h2>Enter your email</h2>
                </Modal.Header>
                <Modal.Body>
                  <div className='row'>
                    <div className='col-12'>
                      <p>We'll send you an email with a password reset link.</p>
                      <label htmlFor='people'>Email</label>
                      <input
                        type='email'
                        className='form-control'
                        value={forgotEmail}
                        onChange={(e) => setForgotEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className='btn btn-primary'
                    data-bs-dismiss='modal'
                    type='submit'
                  >
                    Send email
                  </button>
                </Modal.Footer>
              </form>
            </Modal>

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
