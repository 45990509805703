import React, { useEffect } from 'react';
import 'cleave.js/dist/addons/cleave-phone.ng';
import Cleave from 'cleave.js/react';
import { MyPhoneInput, MySelect, MyTextInput } from 'Atoms/Formik';
import { Countries, States } from 'Lib/constants';
import { Formik, Form } from 'formik';
import { PUCPaymentSchema } from 'Lib/validators';
import API from 'Services/api';
import router from 'next/router';

export default function Payment({
  setPaymentLoading,
  handleClose,
  grandTotal,
}) {
  var initalValues = {
    creditCardValue: '',
    creditCardType: '',
    expDate: '',
    cvv: '',
    firstName: '',
    lastName: '',
    country: 'US',
    address: '',
    city: '',
    state: 'AL',
    zip: '',
    mobile: '',
  };
  if (localStorage.getItem('customer_data')) {
    const customerData = JSON.parse(localStorage.getItem('customer_data'));
    initalValues = {
      ...initalValues,
      ...customerData,
    };
  }

  useEffect(() => {
    function apiHit() {
      let accept = document.createElement('script');
      accept.setAttribute('src', 'https://jstest.authorize.net/v1/Accept.js');
      document.body.appendChild(accept);
      if (process.client) {
        window.checkout = this.checkout;
      }
    }
    apiHit();
  }, []);

  return (
    <div>
      <Formik
        initialValues={initalValues}
        validationSchema={PUCPaymentSchema}
        onSubmit={async (values) => {
          const {
            firstName,
            lastName,
            email,
            country,
            address,
            city,
            stateProvince,
            mobile,
            zip,
          } = values;
          if (values.expDate.length === 4) {
            var authData = {};
            authData.clientKey = process.env.NEXT_PUBLIC_PAY_CLIENT_KEY;
            authData.apiLoginID = process.env.NEXT_PUBLIC_PAY_API_LOGIN_ID;
            var cardData = {};
            cardData.cardNumber = values.creditCardValue;
            cardData.month = values?.expDate?.slice(0, 2);
            cardData.year = values?.expDate?.slice(2);
            cardData.cardCode = values?.cvv;

            var secureData = {};
            secureData.authData = authData;
            secureData.cardData = cardData;
            const handlePayment = async (response) => {
              const { dataDescriptor, dataValue } = response;
              const updatedReservationData = JSON.parse(
                localStorage.getItem('updated_data')
              );
              console.log('UpdatedReservationData', updatedReservationData);
              const obj = {
                ...updatedReservationData,
                dataValue,
                dataDescriptor,
                customerData: {
                  firstName,
                  lastName,
                  email,
                  country,
                  address,
                  city,
                  stateProvince,
                  mobile,
                  zip,
                },
                origin:'vendor'
              };
              try {
                const { data } = await API.post('/reservations/update', obj);
                if (data.message === 'Success') {
                  localStorage.removeItem('billingInfo');
                  localStorage.setItem(
                    'confirmed_data',
                    JSON.stringify(data?.data)
                  );
                  router.push('/change-confirmation');
                  handleClose();
                }
              } catch (error) {
                console.log(error);
              }
            };
            function responseHandler(response) {
              setPaymentLoading(true);
              setTimeout(() => {
                if (response.messages.resultCode === 'Error') {
                  var i = 0;
                  while (i < response.messages.message.length) {
                    console.log(
                      response.messages.message[i].code +
                        ': ' +
                        response.messages.message[i].text
                    );
                    i = i + 1;
                  }
                } else {
                  handlePayment(response.opaqueData);
                }
              }, 3000);
            }
            Accept.dispatchData(secureData, responseHandler);
          }
        }}
      >
        {(props) => {
          console.log(props.values);

          const onCreditCardChange = (e) => {
            props.setFieldValue('creditCardValue', e.target.rawValue);
          };

          function onCreditCardTypeChanged(type) {
            props.setFieldValue('creditCardType', type);
          }

          const onDateChange = (e) => {
            props.setFieldValue('expDate', e.target.rawValue);
          };
          function onCVVChange(e) {
            props.setFieldValue('cvv', e.target.rawValue);
          }

          return (
            <Form>
              <div style={{ marginTop: '1em' }}>
                <div>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '21px',
                      marginBottom: '0.5em',
                    }}
                  >
                    Card Details
                  </p>
                  <div style={{ position: 'relative' }}>
                    <Cleave
                      placeholder='Enter credit card number'
                      options={{
                        creditCard: true,
                        onCreditCardTypeChanged,
                      }}
                      name='creditCardValue'
                      onBlur={props.handleBlur}
                      onChange={(e) => onCreditCardChange(e)}
                      style={{ width: '100%' }}
                    />
                    {props.values?.creditCardType?.toLowerCase() !==
                      'unknown' && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                          fontWeight: 'bold',
                          fontSize: '16px',
                        }}
                      >
                        {props.values?.creditCardType?.toUpperCase()}
                      </div>
                    )}
                  </div>
                  {props.touched.creditCardValue &&
                    props.errors.creditCardValue && (
                      <div
                        id='feedback'
                        style={{
                          marginLeft: '0.5rem',
                          marginBottom: '0.5rem',
                          color: '#E95F5F',
                        }}
                      >
                        {props.errors.creditCardValue}
                      </div>
                    )}

                  <div style={{ display: 'flex' }}>
                    <div>
                      <Cleave
                        placeholder='MM/YY'
                        options={{ date: true, datePattern: ['m', 'y'] }}
                        onChange={onDateChange}
                        name='expDate'
                        onBlur={props.handleBlur}
                      />
                      {props.touched.expDate && props.errors.expDate && (
                        <div
                          id='feedback'
                          style={{
                            marginLeft: '0.5rem',
                            marginBottom: '0.5rem',
                            color: '#E95F5F',
                          }}
                        >
                          {props.errors.expDate}
                        </div>
                      )}
                    </div>

                    <div>
                      <Cleave
                        placeholder='CVV'
                        options={{
                          blocks: [4],
                          numericOnly: true,
                        }}
                        name='cvv'
                        onBlur={props.handleBlur}
                        onChange={onCVVChange}
                        style={{ flex: '1' }}
                      />
                      {props.touched.cvv && props.errors.cvv && (
                        <div
                          id='feedback'
                          style={{
                            marginLeft: '0.5rem',
                            marginBottom: '0.5rem',
                            color: '#E95F5F',
                          }}
                        >
                          {props.errors.cvv}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <input type='hidden' name='dataValue' id='dataValue' />
                <input
                  type='hidden'
                  name='dataDescriptor'
                  id='dataDescriptor'
                />
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '21px',
                    marginBottom: '0.5em',
                  }}
                >
                  Billing Address
                </p>
                <div className='row'>
                  <div className='col-xl-6 col-md-6'>
                    <label htmlFor='people'>First name*</label>
                    <MyTextInput
                      name='firstName'
                      type='text'
                      className='form-control'
                    />
                  </div>
                  <div className='col-xl-6 col-md-6'>
                    <label htmlFor='people'>Last name*</label>
                    <MyTextInput
                      name='lastName'
                      type='text'
                      className='form-control'
                      id='people'
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-12'>
                    <label htmlFor='country'>Country*</label>
                    <MySelect
                      name='country'
                      className='form-control'
                      placeholder='Choose one...'
                    >
                      {Countries.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.name}
                        </option>
                      ))}
                    </MySelect>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xl-12'>
                    <label htmlFor='address'>Address*</label>
                    <MyTextInput
                      name='address'
                      type='text'
                      className='form-control'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xl-6 col-md-6'>
                    <label htmlFor='city'>City*</label>
                    <MyTextInput
                      name='city'
                      type='text'
                      className='form-control'
                    />
                  </div>
                  <div className='col-xl-6 col-md-6'>
                    <label htmlFor='state'>State/Province*</label>
                    <MySelect name='state' className='form-control'>
                      {States.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.name}
                        </option>
                      ))}
                    </MySelect>
                  </div>
                  <div className='col-xl-6 col-md-6'>
                    <label htmlFor='zip'>Zip*</label>
                    <MyTextInput
                      name='zip'
                      type='text'
                      className='form-control'
                    />
                  </div>
                </div>

                <div className='row' style={{ paddingBottom: '1.5rem' }}>
                  <div className='col-xl-12'>
                    <label htmlFor='mobile'>Mobile*</label>
                    <MyPhoneInput
                      name='mobile'
                      formikObj={props}
                      style={{
                        padding: '5px',
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '2em',
                  }}
                >
                  <button
                    type='button'
                    className='btn btn-primary btn-outline-primary'
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='btn btn-primary ml-2'
                    href='reserve-confirmation.html'
                  >
                    Pay
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
