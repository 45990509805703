import React from 'react';
import Link from 'next/link';

const Index = () => {
  return (
    <div>
      <footer>
        <div className='container' style={{ display: 'flex' }}>
          <div className='col-lg-9 col-md-8'>
            <p style={{ paddingTop: '20px' }}>
              <a
                href='https://portal.ehawaii.gov/page/privacy-policy/'
                target='_blank'
              >
                Privacy Policy
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a href='https://dlnr.hawaii.gov/dsp/' target='_blank'>
                About DLNR/State Parks
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link href='/inquiry'>
                <a>Inquiry</a>
              </Link>
            </p>
            <p>
              Copyright &copy; {new Date().getFullYear()} Hawai'i State Parks.
              All Rights Reserved.
            </p>
          </div>
          <div className='col-lg-3 col-md-4 ' style={{ paddingTop: '10px' }}>
            <p className='text-end'>
              <img src='../images/hawaii-state-parks-logo.png' width='100' />{' '}
              &nbsp; &nbsp;
              <img
                src='../images/Seal_of_the_State_of_Hawaii.svg'
                width='90'
                alt='Seal of the State of Hawaii'
              />
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Index;
