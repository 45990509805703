import { Loader } from 'Atoms/';
import moment from 'moment';
import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import API from 'Services/api';
import { SubHeader } from '..';

const Index = () => {
  const [flag, setFlag] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [resultsPerPage, setResultsPerPage] = useState(25);
  const [pageNo, setPageNo] = useState(1);
  const [noOfPage, setNoOfPage] = useState([]);
  const [sortOrder, setSortOrder] = useState('ASC');
  
  const [parkId, setParkId] = useState(null);

  const pageSet = useMemo(() => {
    const startIndex = parseInt(pageNo / 10) * 10;
    return [...Array(10 + 1).keys()].map((x) => x + startIndex);
  }, [noOfPage]);

  const date = new Date();

  useEffect(() => {
    setParkId(JSON.parse(localStorage.getItem('vendor_login_data')).parkId);
    
    localStorage.setItem(
      'reservationSearch',
      JSON.stringify({
        email: '',
        lastName: '',
        orderNo: '',
        type: '',
        pageNo: 1,
        resultsPerPage: 25,
        dateOfReservation: moment(date).format('YYYY-MM-DD'),
      }),
    );

    async function apiHit(searchReservation, parkIdInitial) {
      setFlag(false);
      try {
        const { data } = await API.get('/vendor/reservations', {
          params: {
            startDate: '',
            endDate: '',
            pageNo,
            resultsPerPage,
            parkId: parkIdInitial
          },
        });

        const items = [];
        for (let i = 1; i <= data?.data?.totalNumOfPages; i++) {
          items.push(i);
        }

        if (data?.message === 'Success') {
          setReservations(data?.data?.result);
          setNoOfPage(items);
          setFlag(true);
        } else {
          throw new Error();
        }
      } catch (err) {
        toast.error('Failed to load reservations');
      } finally {
        setFlag(true);
      }
    }

    if (localStorage.getItem('reservationSearch')) {
      const searchReservation = JSON.parse(
        localStorage.getItem('reservationSearch'),
      );
      
      const parkIdInitial = JSON.parse(localStorage.getItem('vendor_login_data')).parkId
      
      apiHit(searchReservation, parkIdInitial);
    }
  }, [pageNo, resultsPerPage]);

  async function sortByField(sortBy) {
    const searchReservation = JSON.parse(
      localStorage.getItem('reservationSearch'),
    );
    setFlag(false);
    try {
      const { data } = await API.get('/vendor/search', {
        params: {
          ...searchReservation,
          dateOfReservation: moment().format('YYYY-MM-DD'),
          pageNo,
          resultsPerPage,
          sortBy,
          sortOrder,
        },
      });

      const items = [];
      for (let i = 1; i <= data?.data?.totalNumOfPages; i++) {
        items.push(i);
      }

      if (data?.message === 'Success') {
        setReservations(data?.data?.results);
        setNoOfPage(items);
        setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
      } else {
        throw new Error();
      }
    } catch (e) {
      toast.error('Failed to fetch reservations');
    } finally {
      setFlag(true);
    }
  }

  async function exportReservations() {
    try {
      const { data } = await API.get('/vendor/report/reservations', {params: {
        parkId: parkId
      }});
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.csv');
      document.body.appendChild(link);
      link.click();
    } catch {
      toast.error('Could not download report');
    }
  }

  if (!flag) return <Loader />;

  return (
    <>
      <div className='container'>
        <SubHeader />
        <Toaster />

        <div className='row row-padding'>
          <div className='col-xl-7 col-md-7'>
            <h2 style={{ margin: '.5rem 0' }}>
              Active reservations: list view
            </h2>
          </div>
          <div className='col-xl-5 col-md-5 text-end'>
            <Link href='/dashboard'>
              <a
                className='btn btn-primary btn-outline-primary'
                style={{ marginRight: '0.4rem' }}
              >
                Search reservations
              </a>
            </Link>
            <Link href='/calendar-view'>
              <a className='btn btn-primary btn-outline-primary'>
                Calendar view
              </a>
            </Link>
          </div>
        </div>
        <div className='row line-bottom-dark g-3'>
          <div className='col'>
            <label htmlFor='people'>Results per page</label>
          </div>
          <div className='col'>
            <select
              className='form-control'
              id='state'
              placeholder='Choose one...'
              onChange={(e) => setResultsPerPage(e.target.value)}
              value={resultsPerPage}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={250}>250</option>
            </select>
          </div>
          <div className='col-xl-8 col-md-8 text-end'>
            <a
              className='btn btn-primary'
              onClick={exportReservations}
              href='#'
            >
              Export Reservations
            </a>
          </div>
        </div>

        <div className='row line-bottom Hide smaller-text'>
          <div className='col-xl-1 col-md-1 col-1 less-column-padding'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortByField('ORDER_DATE')}
            >
              <strong>Order date/time</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-1 col-1 less-column-padding'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortByField('ORDER_NUMBER')}
            >
              <strong>#</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortByField('NAME')}
            >
              <strong>Name</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-1 col-1 less-column-padding'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortByField('TYPE')}
            >
              <strong>Type</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-1 col-1 less-column-padding'>
            <p
              className='less-padding'
              role='button'
              onClick={() => sortByField('DATE_OF_RESERVATION')}
            >
              <strong>Reservation date</strong>
            </p>
          </div>
          <div className='col-xl-1 col-md-1 col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Ticket</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Qty</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Entry</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Price</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Time</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'>
              <strong>Status</strong>
            </p>
          </div>
          <div className='col-1 less-column-padding'>
            <p className='less-padding'></p>
          </div>
        </div>

        {reservations &&
          reservations.map((reservation, index) => {
            return (
              <div className='row line-bottom smaller-text' key={index}>
                <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
                  <p className='less-padding'>
                    {moment(reservation.orderDT).format('MM/DD/YYYY hh:mm a')}
                  </p>
                </div>
                <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
                  <p className='less-padding'>
                    <span className='showMobile'>#</span>
                    {reservation.orderNo}
                  </p>
                </div>
                <div className='col-md-1 col-12 less-column-padding'>
                  <p className='less-padding'>{reservation.userName}</p>
                </div>
                <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
                  <p className='less-padding'>{reservation.type}</p>
                </div>
                <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
                  <p className='less-padding'>{reservation.reservationDate}</p>
                </div>
                <div className='col-xl-1 col-md-1 col-12 less-column-padding'>
                  <p className='less-padding'>{reservation.ticketName}</p>
                </div>
                <div className='col-md-1 col-12 less-column-padding'>
                  <p className='less-padding'>
                    <span className='showMobile'>Qty:</span> {reservation.qty}
                  </p>
                </div>
                <div className='col-md-1 col-12 less-column-padding'>
                  <p className='less-padding'>
                    <span className='showMobile'>Entry:</span>{' '}
                    {reservation.entry}
                  </p>
                </div>
                <div className='col-md-1 col-12 less-column-padding'>
                  <p className='less-padding'>${reservation.price}</p>
                </div>
                <div className='col-md-1 col-12 less-column-padding'>
                  {/* <p className='less-padding'>Morning 10:00am – 12:00pm</p> */}
                  {reservation.timeSlots.map((time, idx) => {
                    return (
                      <p className='less-padding' key={idx}>
                        {time.label}
                      </p>
                    );
                  })}
                </div>
                <div className='col-md-1 col-12 less-column-padding'>
                  <p className='less-padding'>
                    {reservation.status?.toUpperCase()}
                  </p>
                </div>
                <div className='col-md-1 col-12 less-column-padding'>
                  <p className='less-padding'>
                    <Link
                      href={`reservation-detail/${reservation.reservationId}`}
                    >
                      <a>View</a>
                    </Link>
                  </p>
                </div>
              </div>
            );
          })}

        <div className='row row-padding'>
          <div className='col-md-6 col-sm-6 col-xs-12'>
            <nav aria-label='Page navigation example'>
              <ul className='pagination'>
                <li className='page-item'>
                  <button
                    className='page-link '
                    aria-label='Previous'
                    disabled={pageNo === 1}
                    onClick={() => setPageNo((prev) => prev - 1)}
                  >
                    <span aria-hidden='true'>&laquo;</span>
                  </button>
                </li>

                {pageSet.map((item, idx) => {
                  if (item === 0) return;
                  if (item > noOfPage[noOfPage.length - 1]) return;
                  return (
                    <li
                      className={`page-item ${pageNo === item && 'active'}`}
                      key={idx}
                    >
                      <button
                        className='page-link'
                        onClick={() => setPageNo(item)}
                      >
                        {item}
                      </button>
                    </li>
                  );
                })}
                <li className='page-item'>
                  <button
                    className='page-link'
                    aria-label='Next'
                    onClick={() => setPageNo((prev) => prev + 1)}
                    disabled={pageNo === noOfPage.length}
                  >
                    <span aria-hidden='true'>&raquo;</span>
                  </button>
                </li>
              </ul>
            </nav>

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
