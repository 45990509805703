import { CapacityContext } from 'Lib/contexts/GlobalContext';
import moment from 'moment';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast, { Toaster } from 'react-hot-toast';
import { STATIC_PARK_ID } from 'src/constants/misc';
import { SubHeader } from '..';
import API from '../../../services/api';
import Loader from '../../Atoms/Loader';

const Settings = () => {
  const datepickerRef = useRef(null);

  const [intervals, setIntervals] = useState(null);
  const [scheduledChanges, setScheduledChanges] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [isPermanentUpdate, setIsPermanentUpdate] = useState(false);

  const router = useRouter();
  const { capacities, setCapacities } = useContext(CapacityContext);
  
  const [parkId, setParkId] = useState(null);

  useEffect(() => {
    setParkId(JSON.parse(localStorage.getItem('vendor_login_data')).parkId);
    
    const getData = async (parkIdInitial) => {
      try {
        const { data } = await API.get(`/parks/${parkIdInitial}/capacity/`);
        setIntervals(
          data.data.reduce((obj, interval, i) => {
            return {
              ...obj,
              [`${interval.timeSlotId}_${i}`]: {
                ...interval,
                updateCapacity: '',
              },
            };
          }, {})
        );
      } catch (e) {
        if (e.response.data.error) toast.error(e.response.data.message);
        else toast.error('Something went wrong!');
        setIntervals([]);
      }
    };
    
    const parkIdInitial = JSON.parse(localStorage.getItem('vendor_login_data')).parkId
    
    getData(parkIdInitial);

    const getScheduledChanges = async (parkIdInitial) => {
      try {
        const { data } = await API.get(
          `/parks/${parkIdInitial}/scheduledCapacityChanges/`
        );
        console.log(JSON.stringify(data.data));
        setScheduledChanges(data.data);
      } catch (e) {
        if (e.response.data.error) toast.error(e.response.data.message);
        else toast.error('Something went wrong!');
        setIntervals([]);
      }
    };
    
    
    getScheduledChanges(parkIdInitial);
  }, []);

  const handleUpdate = (id, val) => {
    if (parseInt(val) < 0 || isNaN(parseInt(val))) return;
    setIntervals({
      ...intervals,
      [id]: { ...intervals[id], updateCapacity: !val ? '' : parseInt(val) },
    });
  };

  const clearvalues = () => {
    setIsPermanentUpdate(false);
    setEffectiveDate(null);
    const x = Object.keys(intervals).reduce((obj, id) => {
      return { ...obj, [id]: { ...intervals[id], updateCapacity: '' } };
    }, {});
    setIntervals(x);
  };

  const handleInputKeypress = (e) => {
    if (e.keyCode >= 48 && e.keyCode <= 57) return true;
    if (e.keyCode === 8) {
      if (e.target.value.length === 1) {
        e.target.value = '';
      }
      return true;
    }
    return false;
  };

  const updateCaps = async () => {
    try {
      if (!effectiveDate) {
        toast.error('Please enter an effective date!');
        return;
      }

      const timeSlotsCapacity = Object.keys(intervals).reduce((acc, key) => {
        const int = intervals[key];
        acc.push({
          id: int.id,
          timeSlotId: int.timeSlotId,
          startTime: int.startTime,
          endTime: int.endTime,
          updateCapacity: int.updateCapacity,
          ticket: int.type + ' ' + int.ticket,
          currentCapacity: int.currentCapacity,
        });
        return acc;
      }, []);

      if (!timeSlotsCapacity.length) return;

      const updateCapacity = {
        effectiveDate: moment(effectiveDate).format('YYYY-MM-DD'),
        isPermanentUpdate,
        timeSlotsCapacity,
      };

      localStorage.setItem('updateCapacity', JSON.stringify(updateCapacity));
      setCapacities(updateCapacity);
      router.push('/update-capacity');
    } catch (e) {
      console.log({ ...e });
      toast.error('Something went wrong!');
    }
  };

  return (
    <div className='container'>
      <div>
        <Toaster />
      </div>
      <SubHeader />
      <div className='row row-padding-top line-bottom-dark'>
        <div className='col-12'>
          <h2>Set Capacity</h2>
        </div>
      </div>
      <div className='row line-bottom'>
        <div className='col-xl-2 col-md-2 col-2'>
          <p className='less-padding'>
            <strong>Type</strong>
          </p>
        </div>
        <div className='col-xl-2 col-md-2 col-2'>
          <p className='less-padding'>
            <strong>Ticket</strong>
          </p>
        </div>
        <div className='col-xl-3 col-md-2 col-3'>
          <p className='less-padding'>
            <strong>Timeslot</strong>
          </p>
        </div>
        <div className='col-xl-3 col-md-3 col-3'>
          <p className='less-padding'>
            <strong>Current Capacity</strong>
          </p>
        </div>
        <div className='col-xl-1 col-md-1 col-2 text-end'>
          <p className='less-padding'>
            <strong>Change</strong>
          </p>
        </div>
      </div>
      {intervals === null ? (
        <Loader />
      ) : (
        <>
          {Object.keys(intervals).map((i, ind) => (
            <div key={ind} className='row line-bottom'>
              <div className='col-xl-2 col-md-2 col-2'>
                <p className='less-padding'>
                  {intervals[i].type}
                </p>
              </div>
              <div className='col-xl-2 col-md-2 col-2'>
                <p className='less-padding'>{intervals[i].ticket}</p>
              </div>
              <div className='col-xl-3 col-md-2 col-3'>
                <p className='less-padding'>
                  {intervals[i].startTime} - {intervals[i].endTime}
                </p>
              </div>
              <div className='col-xl-3 col-md-3 col-3'>
                <p className='less-padding'>{intervals[i].currentCapacity}</p>
              </div>
              <div className='col-xl-1 col-md-1 col-2 text-end'>
                <input
                  style={{ width: '7ch' }}
                  className='form-control'
                  type='text'
                  value={intervals[i].updateCapacity}
                  onKeyDown={handleInputKeypress}
                  min={0}
                  aria-label='change number'
                  onChange={(e) =>
                    handleUpdate(
                      `${intervals[i].timeSlotId}_${ind}`,
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          ))}
        </>
      )}
      <div>
        <p>
          <strong>Effective Date</strong>
        </p>
        <form id='myForm'>
          <div className='row row-padding-bottom'>
            <div className='col-md-4 col-sm-4 col-xs-12'>
              <div className='input-group input-daterange'>
                <div className='input-group input-daterange'>
                  <DatePicker
                    id='datefield'
                    minDate={new Date()}
                    selected={effectiveDate}
                    onChange={(date) => setEffectiveDate(date)}
                    placeholderText='Booking Date'
                    ref={datepickerRef}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-5 col-sm-5 col-xs-12'>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='flexRadioDefault'
                  id='flexRadioDefault1'
                  checked={!isPermanentUpdate}
                  style={{ marginTop: '.0rem' }}
                  onChange={() => setIsPermanentUpdate(!isPermanentUpdate)}
                />
                <label
                  className='form-check-label'
                  htmlFor='flexRadioDefault1'
                  style={{ marginTop: '-.4em' }}
                >
                  One day only
                </label>
              </div>
              <div className='form-check form-check-inline'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='flexRadioDefault'
                  id='flexRadioDefault2'
                  checked={isPermanentUpdate}
                  style={{ marginTop: '0rem' }}
                  onChange={() => setIsPermanentUpdate(!isPermanentUpdate)}
                />
                <label
                  className='form-check-label'
                  htmlFor='flexRadioDefault2'
                  style={{ marginTop: '-.4em' }}
                >
                  Change permanently
                </label>
              </div>
            </div>
            <div className='col-md-3'>
              <input
                className='btn btn-primary btn-outline-primary'
                type='button'
                value='Clear'
                style={{ margin: '0px', height: 'inherit', fontWeight: 400 }}
                onClick={() => clearvalues()}
              />

              <input
                type='button'
                value='Update'
                className='btn btn-primary'
                style={{
                  padding: '.405rem 1.25rem .505rem',
                  margin: '0 0 0 8px',
                }}
                href='confirm-capacity-changes.html'
                onClick={() => updateCaps()}
              />
            </div>
          </div>
        </form>
      </div>

      <div>
        <h3>Scheduled Capacity Changes</h3>
        <hr />
        <div className='row line-bottom'>
          <div className='col-12'>
            <p className='less-padding'>
              <strong>Date</strong>
            </p>
          </div>
        </div>

        {scheduledChanges === null ? (
          <Loader />
        ) : (
          <>
            {Object.keys(scheduledChanges).map((i, ind) => (
              <div className='row line-bottom' key={ind}>
                <div className='col-md-5'>
                  <div className='row mb-2'>
                    <div className='col-xl-6 col-md-6 col-6'>
                      <p className='less-padding'>
                        {scheduledChanges[i].effectiveDate}
                      </p>
                    </div>
                    <div className='col-xl-6 col-md-6 col-6'>
                      <p className='less-padding'>
                        {scheduledChanges[i].updateType}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-7'>
                  <Link
                    href={`viewScheduledCapacity/${scheduledChanges[i]._id}`}
                  >
                    <a className='btn btn-primary'>View</a>
                  </Link>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Settings;
