import { createContext, useState } from 'react';

const UserContext = createContext();
const CapacityContext = createContext();

const GlobalProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [capacities, setCapacities] = useState([]);

  return (
    <UserContext.Provider value={{ isLogin, setIsLogin }}>
      <CapacityContext.Provider value={{ capacities, setCapacities }}>
        {children}
      </CapacityContext.Provider>
    </UserContext.Provider>
  );
};

export { GlobalProvider, UserContext, CapacityContext };
