import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import API from 'Services/api';
import { SubHeader } from '..';
import { useRouter } from 'next/router';

const Index = ({ data, id, vendorSuperUser }) => {
  const [show, setShow] = useState(false);
  const [waive, setWaive] = useState(false);
  const [waiveFee, setWaiveFee] = useState(false);
  const router = useRouter();
  const [history, setHistory] = useState(false);
  //console.log('SUPERUSER >>>>>>>>>> ' + vendorSuperUser)
  const [superUser, setSuperUser] = useState(false);
  
  useEffect(() => {
    const getHistory = async () => {
      try {
        const { data } = await API.get(`/vendor/reservation/${id}/history`);
        
        setHistory(data.data);
      } catch (e) {
        
      }
    };
  
    getHistory();
  
    setSuperUser(vendorSuperUser);
  }, []);

  const handleClose = () => {
    setShow(false);
    setWaive(false);
  }
  const handleShow = () => setShow(true);
  const handleCancelReservation=()=>{
    localStorage.setItem("waive",JSON.stringify(waive));
    router.push(`/cancel-reservation/${id}`);
  }
  
  
  
  //force refund
  const [showForce, setShowForce] = useState(false);
  const [forceMessage, setForceMessage] = useState(null);
  const [disableForce, setDisableForce] = useState(false);
  const handleForceRefundShow = () => setShowForce(true);
  
  const handleForceClose = () => {
    setShowForce(false);
    setWaiveFee(false);
  }
  
  const handleForceRefund = async () => {
    try {
      setDisableForce(true);
      
      const { data } = await API.post(`/vendor/manualRefund/${id}`, {
        id: id,
        waiveFee: waiveFee
      });
      
      if(data) {
        setForceMessage('Reservation has been refunded. Please refresh the page to see the changes.') 
        setWaiveFee(false);
      }
    } catch (error) {
      setForceMessage('An error has occured. Please try again.') 
      setWaiveFee(false);
      console.warn(error);
    }
  }
  
  
  
  

  // console.log(data);
  const { customerData, pricingDetails, reservationData } = data;
  // console.log('data', data);
  const { pricingDetails: ticketDetails } = pricingDetails;
  const {
    segment,
    orderNo,
    dateOfReservation,
    createdAt,
    ticketName,
    transactionId,
    referenceTxnId,
    totalPrice,
    buttonFlags,
    codeScanCount,
    codeLastScannedAt
  } = reservationData;
  // console.log('Customer Data', customerData);
  const {
    address,
    city,
    country,
    email,
    firstName,
    lastName,
    stateProvince,
    zip,
    mobile,
    company,
  } = customerData;

  const { cancel, change, force } = buttonFlags;
  const handleSentEmail = async () => {
    try {
      const { data } = await API.post(`/reservations/${id}/email`, {
        email: customerData.email,
      });
      // console.log(data);
      toast.success('Email Sent');
    } catch (error) {
      console.warn(error);
    }
  };

  const handleChangeReserv = () => router.push(`/change-reservation/${id}`);
  return (
    <>
      <div className='container'>
        <div>
          <Toaster />
        </div>
        <SubHeader />
        <div className='row row-padding-top'>
          <div className='col-md-12'>
            <h1>Reservation detail</h1>
          </div>
        </div>
        <div className='row line-bottom'>
          <div className='col-md-8 col-12'>
            <p style={{ marginTop: '0px' }}>
              <strong>Order date/time:</strong>{' '}
              {moment(createdAt).format('MM/DD/YYYY hh:mm a')}
              <br />
              <strong>Order #:</strong> {orderNo}
              <br />
              <strong>Type:</strong>{' '}
              <span style={{ textTransform: 'uppercase' }}>{segment}</span>
              {codeScanCount === 1 &&
                <div>
                  <span><strong>Scanned Time:</strong> {moment(codeLastScannedAt).format('MM/DD/YYYY hh:mm a')}</span>
                </div>
              }              
            </p>
          </div>
          <div className='col-md-4 col-12'>
            <button
              className='btn btn-primary'
              // data-toggle='modal'
              // data-target='#exampleModal'
              onClick={handleSentEmail}
            >
              Email Confirmation
            </button>

            {/* <div
              className='modal fade'
              id='exampleModal'
              tabIndex='-1'
              role='dialog'
              aria-labelledby='exampleModalLabel'
              aria-hidden='true'
            >
              <div
                className='modal-dialog modal-dialog-centered modal-dialog-scrollable'
                role='document'
              >
                <div className='modal-content'>
                  <div className='modal-header text-start'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <p>Email sent</p>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button className='btn btn-primary' data-dismiss='modal'>
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className='row line-bottom'>
          <div className='col-xl-1 col-md-1 col-2'>
            <p className='less-padding'>
              <strong>Ticket</strong>
            </p>
          </div>
          <div className='col-xl-4 col-md-4 col-6'>
            <p className='less-padding'>
              {/* {ticketDetails[0].ticket === 'Entry' ? (
                <strong>{`${ticketDetails[0].ticket} x ${ticketDetails[0].qty}`}</strong>
              ) : (
                <strong>
                  {`${ticketDetails[0].ticket.split(' ')[0]} x ${
                    ticketDetails[0].qty
                  } + Entry x ${ticketDetails[1].qty}`}
                </strong>
              )} */}
              {/* <strong>Parking x 2 + Entry x 3</strong> */}
              {ticketDetails.map((ticketData, idx, arr) =>
                arr.length - 1 === idx ? (
                  <strong>{`${ticketData.ticket} x ${ticketData.qty}`}</strong>
                ) : (
                  <strong>{`${ticketData.ticket} x ${ticketData.qty} + `}</strong>
                )
              )}

              <br />
              <br />
              {moment(new Date(dateOfReservation)).format('MM/DD/YYYY')}
              <br />
              {/* Parking (9:00am– 12:00pm)
              <br />
              Parking (12:00pm – 1:00pm) */}
              {reservationData.timeslots.map((time, idx) => {
                return (
                  <span key={idx}>
                    {`Parking (${time.label})`}
                    <br />
                  </span>
                );
              })}
            </p>
            <p>
              <button
                className='btn btn-primary btn-outline-primary'
                onClick={handleShow}
                disabled={!cancel}
              >
                Cancel
              </button>
              {/* <Link href={`/change-reservation/${id}`}>
                <a className='btn btn-primary btn-outline-primary ml-2'>
                  Change
                </a>
              </Link> */}
              <button
                className='btn btn-primary btn-outline-primary ml-2'
                onClick={handleChangeReserv}
                disabled={!change}
              >
                Change
              </button>
              
              {force && reservationData.status === 'confirmed' ?
                <button
                  className='btn btn-primary btn-outline-primary mt-2'
                  onClick={handleForceRefundShow}
                  disabled={disableForce}
                >
                  Issue Manual Refund
                </button>
              : ''}

              <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                  <div className='row'>
                    <div className='col-md-12'>
                      <p>
                        <strong>
                          Please check the box below if you would like NOT TO
                          CHARGE the $5 handling fee for this cancellation.
                        </strong>
                      </p>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={waive}
                          onChange={(e)=>setWaive(e.target.checked)}
                          id='flexCheckDefault'
                        />
                        <label
                          className='form-check-label'
                          htmlFor='flexCheckDefault'
                        >
                          Waive $5 cancellation fee
                        </label>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className='btn btn-primary btn-outline-primary'
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  {/* <Link href={`/cancel-reservation/${id}`}>
                    <a className='btn btn-primary'>Confirm cancellation</a>
                  </Link> */}
                  <button
                    className='btn btn-primary'
                    onClick={handleCancelReservation}
                  >
                    Confirm Cancellation
                  </button>
                </Modal.Footer>
              </Modal>
              
              
              
              
              
              
              
              
              
              
              <Modal show={showForce} onHide={handleForceClose}>
                <Modal.Body>
                  <div className='row'>
                    <div className='col-md-12'>
                      <p>
                        <strong>
                          This will manually issue a refund for a reservation. This cannot be undone.
                        </strong>
                      </p>
                      {forceMessage ?
                      <div className="alert alert-danger" role="alert">
                        {forceMessage}
                      </div>
                      : ''}
                      {superUser == 1 && segment != 'puc' ?
                        <div>
                          <p>
                            <strong>
                              Please check the box below to waive the processing fee.
                            </strong>
                          </p>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={waiveFee}
                              onChange={(e)=>setWaiveFee(e.target.checked)}
                              id='flexCheckDefault'
                            />
                            <label
                              className='form-check-label'
                              htmlFor='flexCheckDefault'
                            >
                              Refund processing fee
                            </label>
                          </div>
                        </div>
                      : ''}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className='btn btn-primary btn-outline-primary'
                    onClick={handleForceClose}
                  >
                    {disableForce
                      ? 'Close'
                      : 'Cancel'
                    }
                  </button>
                  <button
                    className='btn btn-primary'
                    onClick={handleForceRefund}
                    disabled={disableForce}
                  >
                    Confirm Refund
                  </button>
                </Modal.Footer>
              </Modal>
              
              
              
              
              
              
              
              
              
              {/* <div
                className='modal fade'
                id='exampleModalName'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalNameLabel'
                aria-hidden='true'
              >
                <div
                  className='modal-dialog modal-dialog-centered modal-dialog-scrollable'
                  role='dialog'
                >
                  <div className='modal-content'>
                    <div className='modal-header text-start'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <p>
                            <strong>
                              Please check the box below if you would like NOT
                              TO CHARGE the $5 handling fee for this
                              cancellation.
                            </strong>
                          </p>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='flexCheckDefault'
                            />
                            <label
                              className='form-check-label'
                              htmlFor='flexCheckDefault'
                            >
                              Waive $5 cancellation fee
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        className='btn btn-primary btn-outline-primary'
                        data-toggle='modal'
                        data-target='#exampleModalName'
                        aria-label='Close'
                      >
                        Cancel
                      </button>{' '}
                      <Link href={`/cancel-reservation/${id}`}>
                        <button
                          className='btn btn-primary'
                          href='#'
                          data-toggle='modal'
                          data-target='#exampleModalName'
                        >
                          Confirm cancellation
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
            </p>
          </div>
          <div className='col-xl-6 col-md-6 col-12'>
            <div className='row line-bottom'>
              <div className='col-xl-5 col-md-5 col-5'>
                <p className='less-padding'>Order status:</p>
              </div>
              <div className='col-xl-6 col-6'>
                <p
                  className='less-padding'
                  style={{ textTransform: 'capitalize' }}
                >
                  {reservationData.status}
                </p>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-5 col-md-5 col-5'>
                <p className='less-padding'>
                  <strong>Total:</strong>
                </p>
              </div>
              <div className='col-xl-6 col-6'>
                <p className='less-padding'>
                  <strong>${Number(totalPrice).toFixed(2)}</strong>
                </p>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-5 col-md-5 col-5'>
                <p className='less-padding'>Transaction #:</p>
              </div>
              <div className='col-xl-6 col-6'>
                <p className='less-padding'>{transactionId}</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-5 col-md-5 col-5'>
                <p className='less-padding'>Reference Transaction ID#</p>
              </div>
              {referenceTxnId !== undefined && (
                <div className='col-xl-6 col-6'>
                  <p className='less-padding'>{referenceTxnId}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {segment === 'puc' && (
          <div className='row'>
            <div className='col-xl-2 col-md-2 col-2'>
              <p className='less-padding'>Company:</p>
            </div>
            <div className='col-xl-6 col-6'>
              <p className='less-padding'>{company}</p>
            </div>
          </div>
        )}

        {customerData && (
          <>
            <div className='row'>
              <div className='col-xl-2 col-md-2 col-2'>
                <p className='less-padding'>Name:</p>
              </div>
              <div className='col-xl-6 col-6'>
                <p className='less-padding'>
                  {firstName} {lastName}
                </p>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-2 col-md-2 col-2'>
                <p className='less-padding'>Email:</p>
              </div>
              <div className='col-xl-6 col-6'>
                <p className='less-padding'>{email}</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-xl-2 col-md-2 col-2'>
                <p className='less-padding'>Telephone:</p>
              </div>
              <div className='col-xl-6 col-6'>
                <p className='less-padding'>{mobile}</p>
              </div>
            </div>

            {customerData?.pucLicense && (
              <div className='row'>
                <div className='col-xl-2 col-md-2 col-2'>
                  <p className='less-padding'>PUC License #:</p>
                </div>
                <div className='col-xl-6 col-6'>
                  <p className='less-padding'>{customerData.pucLicense}</p>
                </div>
              </div>
            )}

            <div className='row line-bottom'>
              <div className='col-xl-2 col-md-2 col-2'>
                <p className='less-padding'>Address:</p>
              </div>
              <div className='col-xl-6 col-6'>
                <p className='less-padding'>
                  {address}
                  <br />
                  {city}, {stateProvince} {country} {zip}
                </p>
              </div>
            </div>
          </>
        )}
        
        
        {history && superUser == 1 && (
          <div>
            <p class="lead"><strong>Reservation History</strong></p>
            <div class="accordion" id="historyAccordion">
              {history.map((entry, index) => (
                <div class="card">
                  <div class="card-header" id={'heading' + index}>
                    <a href="#" class="d-block" data-toggle="collapse" data-target={'#collapse' + index} aria-expanded="true" aria-controls={'collapse' + index}>
                      <strong>
                        {moment(entry.updatedAt).format('MM/DD/YYYY hh:mm a')}
                        {entry.dataUpdated.status && 
                          <span> - {entry.dataUpdated.status}</span>
                        }
                      </strong>
                    </a>
                  </div>
                
                  <div id={'collapse' + index} class="collapse" aria-labelledby={'heading' + index} data-parent="#historyAccordion">
                    <div class="card-body">
                      <pre><code><small>{JSON.stringify(entry, null, 2)}</small></code></pre>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        
        
        <div className='row row-padding-top'>
          <div className='col-md-12'>
            <Link href='/search-results'>
              <a className='btn btn-primary btn-outline-primary'>Back</a>
            </Link>
            {/* <a
              className='btn btn-primary btn-outline-primary'
              href='change-reservation.html'
            >
              Change reservation
            </a> */}
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};
export default Index;
