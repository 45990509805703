import axios from 'axios';
import Cookies from 'js-cookie';
import {Config} from 'Lib/config';
import https from "https";
const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

axios.defaults.httpsAgent=new https.Agent({
  rejectUnauthorized:false
});
axios.defaults.headers.common = {
  'api-key': Config.API_KEY,
  'pragma': 'no-cache',
  'cache-control': 'no-cache',
};

const API = axios.create({
  baseURL: Config.API_URL,
  config,
});

API.interceptors.request.use((req) => {
  if (Cookies.get('token')) {
    req.headers.Authorization = `Bearer ${Cookies.get('token')}`;
  }
  return req;
});

export default API;
